import axios from 'axios'
import Vue from 'vue'

const GraphqlQueries = {}

async function makeRequest(query) {
    try {
        const request = await axios({
            url: 'https://api.torkhub.com/v1/graphql',
            method: 'post',
            headers: {
                'x-hasura-admin-secret': process.env.VUE_APP_HASURA_KEY
            },
            data: query
        }).then((result) => {
            if (result.data.errors) {
                return result.data.errors[0].message
            } else {
                return result.data
            }
        })
        const results = await request
        return results
    } catch (error) {
        this.$Sentry.captureException(error)
    }
}

GraphqlQueries.getOrdersByUsers = (userIds) => {
    const query = {
        operationName: 'get_admin_orders',
        query: `query get_admin_orders($user_ids: [Int!], $cre_dttm: order_by = desc) {
      orders(where: {user_id: {_in: $user_ids}}, order_by: {cre_dttm: $cre_dttm}) {
        user_id
        order_number
        status
        price
        total
        tax
        cre_dttm
        order_items {
          order_items_id
          order_number
          assigned_user_id
          status
          assigned_user {
            full_name
            first_name
            last_name
          }
          user{
            first_name
            last_name
            full_name
            email
          }
          product {
            product_id
            product_name
            product_type
          }
          order_fulfillment_items {
            product_id
            product {
              product_name
            }
            fulfilled_quantity
            fulfillment_method
            quantity
            status
          }
          order_requirement_items {
            requirement {
              req_id
              req_name
            }
            status
            submission_data
            submitted_by
            submitted_dttm
            assigned_user_id
            order_requirement_items_id
          }
        }
      }
    }`,
        variables: userIds
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamOrdersByProduct = (data) => {
    const query = {
        operationName: 'get_admin_orders',
        query: `query get_admin_orders($user_ids: [Int!], $cre_dttm: order_by = desc, $product_type: String = "Registration") {
      orders(where: {user_id: {_in: $user_ids}, status: {_eq: "paid"}}, order_by: {cre_dttm: $cre_dttm}) {
        user_id
        order_number
        status
        price
        total
        tax
        cre_dttm
        order_items(where: {product: {product_type: {_eq: $product_type}}}) {
          order_items_id
          order_number
          assigned_user_id
          status
          event_product_id
          assigned_user {
            user_id
            full_name
            first_name
            last_name
          }
          user {
            user_id
            first_name
            last_name
            full_name
            email
          }
          product {
            product_id
            product_name
            product_type
            product_requirements {
              req_id
              product_requirements_id
              requirement {
                req__display_name
                req_description
                req_html
                req_id
                req_name
              }
            }
          }
          order_fulfillment_items {
            product_id
            product {
              product_name
            }
            fulfilled_quantity
            fulfillment_method
            quantity
            status
          }
          order_requirement_items {
            requirement {
              req_id
              req_name
            }
            status
            submission_data
            submitted_by
            submitted_dttm
            assigned_user_id
            order_requirement_items_id
          }
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}
GraphqlQueries.getTeamOrders = (data) => {
    const query = {
        operationName: 'get_admin_orders',
        query: `query get_admin_orders($user_ids: [Int!], $cre_dttm: order_by = desc, $org_id: Int = ${Vue.prototype.$org.org_id}) {
      orders(where: {user_id: {_in: $user_ids}, status: {_eq: "paid"}, org_id: {_eq: $org_id}}, order_by: {cre_dttm: $cre_dttm}) {
        user_id
        order_number
        status
        price
        total
        tax
        cre_dttm
        order_items(where: {event_product_id: {_is_null: false}}) {
          order_items_id
          order_number
          assigned_user_id
          status
          event_product_id
          assigned_user {
            user_id
            full_name
            first_name
            last_name
          }
          user {
            user_id
            first_name
            last_name
            full_name
            email
          }
          order_fulfillment_items {
            product_id
            product {
              product_name
            }
            fulfilled_quantity
            fulfillment_method
            quantity
            status
          }
          order_requirement_items {
            requirement {
              req_id
              req_name
            }
            status
            submission_data
            submitted_by
            submitted_dttm
            assigned_user_id
            order_requirement_items_id
          }
          event_product {
            channels
            event_id
            photos
            product {
              product_id
              product_name
              product_type
              product_description
              is_wristband
              is_hardcard
            }
            hard_card_levels {
              is_temp
            }
            event {
              status
              event_id
              name
              event_display_name
            }
          }
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getRequirementsByOrg = (data) => {
    const query = {
        operationName: 'get_requirements',
        query: `query get_requirements($org_id: Int = ${Vue.prototype.$org.org_id}) {
      requirements(where: {org_id: {_eq: $org_id}}, order_by: {req_name: asc}) {
        req_id
        req_name
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getItemsByOrg = (data) => {
    const query = {
        operationName: 'products',
        query: `query products($org_id: Int, $status: String = "active", $product_type: String = "fulfillment") {
      products(where: {org_id: {_eq: $org_id}, product_type: {_ilike: $product_type}, status: {_eq: $status}}) {
        product_id
        product_display_name
        product_name
        price
        fill_input_type
        fulfillment_image
        fulfillment_color
        product_type
        is_media
        status
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getMediaFillItemsByOrg = (data) => {
    const query = {
        operationName: 'products',
        query: `query products($org_id: Int = ${Vue.prototype.$org.org_id}) {
      products(where: {org_id: {_eq: $org_id}, product_display_name: {_ilike: "%vest%"}}) {
        product_id
        product_display_name
        product_name
        price
        fill_input_type
        product_type
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOrdersByOrderNumber = (orderNumber) => {
    const query = {
        operationName: 'get_orders_by_order_number',
        query: `query get_orders_by_order_number($order_number: Int) {
      orders(where: {order_number: {_eq: $order_number}}) {
        user_id
        order_number
        status
        price
        total
        tax
        cre_dttm
        order_items {
          order_items_id
          order_number
          status
          user{
            first_name
            last_name
            full_name
            email
          }
          product {
            product_name
            product_type
          }
          event_product {
          event {
            event_id
            start_date
            end_date
            name
            image
            venue{
              venue_id
              venue_name
              city
              state_province
              postal_code
            }
          }
        }
          order_fulfillment_items {
            product_id
            product {
              product_name
            }
            fulfilled_quantity
            fulfillment_method
            quantity
            status
          }
          order_requirement_items {
            status
            requirement {
              req_id
              req_name
              req_description
              formsite_id
              formsite_name
            }
            status
            submission_data
            submitted_by
            submitted_dttm
            assigned_user_id
            order_requirement_items_id
          }
        }
      }
    }`,
        variables: orderNumber
    }
    return makeRequest(query)
}

GraphqlQueries.getOrdersByUserEvent = (data) => {
    const query = {
        operationName: 'get_orders_by_order_number',
        query: `query get_orders_by_order_number($user_id: Int, $event_id: Int) {
      orders(where: {status: {_eq: "paid"}, order_items: {assigned_user_id: {_eq: $user_id}}}) {
        user_id
        order_number
        status
        price
        total
        tax
        stripe_last4
        stripe_receipt_url
        stripe_payment_id
        stripe_payload_id: stripe_payload(path: "$.id")
        payment_type
        cre_dttm
        order_items(where: {event_product: {event: {event_id: {_eq: $event_id}}}, assigned_user_id: {_eq: $user_id}}) {
          order_items_uuid
          order_items_id
          order_number
          ticket_generated
          attendee_can_transfer
          assigned_user_id
          assigned_user {
            email
            first_name
            last_name
            full_name
          }
          status
          purchaser : user {
            first_name
            last_name
            full_name
            email
          }
          product {
            product_name
            product_type
            product_display_name
            default_location
            delivery
            product_id
            transferable
            fulfillment_color
            fulfillment_image
            product_requirements {
              req_id
              product_requirements_id
              requirement {
                req__display_name
                req_description
                req_html
                req_id
                req_name
              }
            }
          }
          event_product {
            delivery_methods
            variant_display_name
            access_end
            access_start
            event_products_id
            display_end_date
            display_start_date
            checkin_info
            transferable
            event {
              event_id
              start_date
              end_date
              name
              image
              timezone
              venue {
                venue_id
                venue_name
                city
                state_province
                postal_code
              }
              organization {
                org_name
              }
              event_display_name
              event_display_name_2
            }
          }
          order_fulfillment_items {
            product_id
            product {
              product_name
              fulfillment_color
              fulfillment_image
            }
            fulfilled_quantity
            fulfillment_method
            quantity
            status
          }
          order_requirement_items {
            status
            due_date
            requirement {
              req_id
              req_name
              req_description
              formsite_id
              formsite_name
              formsite_url
              url_slugs
              platform
              form_uuid
              expiration_dttm
              expiration_type
              form {
                auto_approve
              }
            }
            status
            submission_data
            submitted_by
            submitted_dttm
            assigned_user_id
            order_requirement_items_id
          }
          seatsio_order_item {
            sio_seat_id
            status
            seatsio_payload(path: "objects")
          }
          ticket_transfers(order_by: {ticket_transfer_id: desc}, where: {transfer_status: {_neq: "cancelled"}}) {
            order_item_id
            ticket_transfer_id
            transfer_status
            transfer_to
            transfer_from: user_id
            receiver: transfer_user  {
              email
              full_name
              cell_phone
              first_name
              user_id
            }
            sender:user {
              email
              cell_phone
              full_name
              first_name
              user_id
            }
          }
        }
        organization {
          stripe_id
          org_name
          org_id
          org_legal_name
        }
      }
      event: events(where: {event_id: {_eq: $event_id}}) {
        event_id
        ticket_release_dttm
        event_display_name
        event_display_name_2
        start_date
        end_date
        timezone
        image
        venue {
          venue_id
          venue_name
          address_1
          address_2
          city
          state_province
          postal_code
        }
        organization {
          org_id
          org_name
          org_logo
          org_website
          subdomain
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOrderItemsByOrderNumber = (data) => {
    const query = {
        operationName: 'order_items',
        query: `query order_items($order_number: Int) {
      order_items(where: {order_number: {_eq: $order_number}}) {
        order_number
        order_items_id
        quantity
        price
        user_id
        wristband_id
        order {
          team_id
        }
        purchaser : user {
          email
          user_id
          full_name
          last_name
          first_name
          cell_phone
          is_house_account
        }
        assigned_user {
          email
          user_id
          full_name
          last_name
          first_name
          cell_phone
          is_house_account
        }
        event_product_id
        product {
          product_id
          product_name
          product_type
          requirements
          product_display_name
          fulfillment_image
          fulfillment_color
          add_ons
        }
        event_product {
          event {
            event_id
            start_date
            end_date
            name
            image
          }
        }
        order_fulfillment_items {
          fulfillment_items_id
          product {
            product_id
            product_name
            product_display_name
            fulfillment_image
            fulfillment_color
            is_wristband
            is_hardcard
          }
        }
        order_requirement_items {
          order_requirement_items_id
          requirement {
            req_id
            req_name
          }
          due_date
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOrderItemsByOrderItemId = (data) => {
    const query = {
        operationName: 'order_items',
        query: `query order_items($order_items_id: Int) {
      order_items(where: {order_items_id: {_eq: $order_items_id}}) {
        allocation_id
        assigned_dttm
        assigned_user_id
        attendee_can_transfer
        created_dttm
        discount
        discount_reason
        event_product_id
        item_cc_fee
        item_fee_1
        item_fee_2
        item_fee_3
        item_fee_4
        item_fee_5
        item_platform_fee
        media_oulet_id
        notes
        order_items_id
        order_items_qr_uri
        order_items_uuid
        order_number
        partner_id
        pay_plan
        price
        product_id
        purchaser_can_transfer
        quantity
        source_channel
        status
        submitted_dttm
        team_id
        updated_dttm
        user_id
        wristband_id
        order {
          cre_dttm
          order_create_dttm
          team_id
          team {
            team_name
          }
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOrdersByProductType = (data) => {
    const query = {
        operationName: 'get_orders_by_product_type',
        query: `query get_orders_by_product_type($productType: String, $orgID: Int = ${Vue.prototype.$org.org_id}) {
      order_items(where: {product: {product_type: {_eq: $productType}, org_id: {_eq: $orgID}}}) {
        assigned_user_id
        submitted_dttm
        product {
          product_display_name
        }
        status
        order_requirement_items(limit: 1) {
          submitted_by
          submitted_dttm
        }
        user {
          full_name
        }
        order_items_id
        order {
          order_number
          cre_dttm
          status
          team_id
        }
        assigned_user {
          first_name
          full_name
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getRegistrationOrderItemById = (orderInfo) => {
    const query = {
        operationName: 'get_orders_by_order_number',
        query: `query get_orders_by_order_number($order_number: Int, $order_items_id: Int) {
      orders(where: {order_number: {_eq: $order_number}}) {
        user_id
        order_number
        status
        price
        total
        tax
        cre_dttm
        order_items(where: {_and: {order_items_id: {_eq: $order_items_id}}}) {
          order_items_id
          order_number
          status
          notes
          check_in_note
          user {
            first_name
            last_name
            full_name
            email
          }
          assigned_user {
            full_name
          }
          product {
            product_name
            product_type
          }
          order_fulfillment_items {
            product_id
            product {
              product_name
            }
            fulfilled_quantity
            fulfillment_method
            quantity
            status
          }
          order_requirement_items {
            requirement {
              req_id
              req_name
              req_description
              formsite_id
              formsite_name
            }
            status
            submission_data
            submitted_by
            submitted_dttm
            assigned_user_id
            accept_notes
            reject_notes
            cancel_notes
            order_requirement_items_id
          }
        }
      }
    }`,
        variables: orderInfo
    }
    return makeRequest(query)
}

GraphqlQueries.getApprovalsByOrderNumber = (orderNumber) => {
    const query = {
        operationName: 'get_approvals_by_order_number',
        query: `query get_approvals_by_order_number($order_number: Int, $order_items_id: Int) {
      order_items(where: {order_number: {_eq: $order_number}, order_items_id: {_eq: $order_items_id}, product: {product_type: {_eq: "Registration"}}}) {
        assigned_user {
          email
          user_id
          full_name
          team_users {
            team_user_id
            team_id
            team {
              team_name
            }
          }
        }
        status
        purchaser : user {
          full_name
          email
          user_id
        }
        product {
          product_type
          product_name
        }
        order_fulfillment_items {
          product_id
          product {
            product_name
          }
          fulfilled_quantity
          fulfillment_method
          quantity
          status
        }
        order_requirement_items {
          requirement {
            req_id
            req_name
            req_description
            formsite_id
            formsite_name
          }
          status
          notes
          submission_data
          submitted_by
          submitted_dttm
          assigned_user_id
          order_requirement_items_id
        }
      }
    }
    `,
        variables: orderNumber
    }
    return makeRequest(query)
}

GraphqlQueries.getOrderDetails = (orderNumber) => {
    const query = {
        operationName: 'get_order_details',
        query: `query get_order_details($order_number: Int) {
      orders(where: {order_number: {_eq: $order_number}}) {
        user {
          user_id
          first_name
          last_name
          full_name
          email
        }
        team_id
        team {
          team_name
        }
        order_number
        status
        total
        price
        fees
        order_create_dttm
        stripe_payload
        order_items {
          order_number
          price
          order_items_id
          order_items_uuid
          status
          media_status
          updated_dttm
          created_dttm
          check_in_note
          assigned_dttm
          assigned_user {
            user_id
            first_name
            last_name
            full_name
            email
            headshot
            profile_photo
          }
          user {
            user_id
            first_name
            last_name
            full_name
            email
          }
          product {
            default_location
            product_id
            add_ons
            product_name
            product_type
            product_display_name
            price
            is_media
          }
          event_product {
            event_products_id
            is_variant
            variant_display_name
            event_id
            event {
              url_name
            }
            seatsio_event_products {
              sio_channels
            }
          }
          order {
            fees
            team {
              team_name
              team_users(where: {role_id: {_eq: 2}}) {
                user {
                  driver:full_name
                }
              }
            }
            team_id
          }
          order_requirement_items{
            order_requirement_items_id
            status
            submission_data
            requirement {
              req_id
              req_name
            }
          }
          order_fulfillment_items (order_by: {product: {product_name: asc}})  {
            fulfillment_items_id
            fulfillment_method
            quantity
            fulfilled_quantity
            status
            is_media
            media_number
            product {
              product_id
              product_name
              fulfillment_color
              fulfillment_image
              product_display_name
              price
              event_products {
                price
              }
              product_type
              fill_input_type
            }
          }
          seatsio_order_item {
            sio_seat_id
            seatsio_payload(path: "objects")
            holdToken
            status
          }
          team {
            team_users(where: {role_id: {_eq: 2}}) {
              user {
                driver:full_name
              }
            }
          }
        }
      }
    }`,
        variables: orderNumber
    }
    return makeRequest(query)
}

GraphqlQueries.getOrderItemsByAssignedUserEvent = (data) => {
    const query = {
        operationName: 'get_oi_by_assigned_userr',
        query: `query get_oi_by_assigned_userr($assigned_user_id: Int, $event_id: Int, $order_status: String = "paid") {
      order_items(where: {assigned_user: {user_id: {_eq: $assigned_user_id}}, event_product: {event_id: {_eq: $event_id}}, order: {status: {_eq: $order_status}}}) {
        order_number
        order_items_id
        status
        updated_dttm
        created_dttm
        assigned_user {
          user_id
          first_name
          last_name
          full_name
          email
        }
        product {
          fill_input_type
          product_id
          add_ons
          product_name
          product_type
          product_display_name
          price
        }
        event_product {
          event_products_id
          event_id
        }
        order_requirement_items {
          order_requirement_items_id
          status
          submission_data
          requirement {
            req_id
            req_name
          }
        }
        order_fulfillment_items (order_by: {product: {product_name: asc}}) {
          fulfillment_items_id
          fulfillment_method
          quantity
          status
          product {
            product_id
            product_name
            product_display_name
            price
            event_products {
              price
            }
          }
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOrdersByUser = (userId) => {
    const query = {
        operationName: 'get_orders_by_user',
        query: `query get_orders_by_user($user_id: Int) {
      orders(where: {user_id: {_eq: $user_id}}) {
        cre_dttm
        status
        stripe_payment_id
        total
        user_id
        org_id
        order_number
        invoice_id
      }
    }`,
        variables: userId
    }
    return makeRequest(query)
}

GraphqlQueries.getSpectatorTickets = (data) => {
    const query = {
        operationName: 'order_items',
        query: `query order_items($assigned_user_id: Int, $user_id: Int, $event_id: Int, $_eq: String = "Spectator Ticket") {
      order_items(where: {assigned_user_id: {_eq: $assigned_user_id}, _or: {user_id: {_eq: $user_id}, event_product: {event_id: {_eq: $event_id}, product: {product_type: {_eq: $_eq}}}}}) {
        order_items_id
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getEventsByOrg = (orgId) => {
    const query = {
        operationName: 'get_events_by_org',
        query: `query get_events_by_org($org_id: Int = ${Vue.prototype.$org.org_id}) {
      events(where: {org_id: {_eq: $org_id}}, order_by: {start_date: asc}) {
        org_id
        event_display_name
        event_display_name_2
        start_date
        url_name
        end_date
        name
        image
        internal_use
        event_id
        status
        type
        venue {
          venue_name
          website
          venue_id
          state_province
          postal_code
          phone_number
          country
          city
          address_1
          address_2
        }
        event_products {
          event_products_id
          product_id
          quantity
          max_quantity
          status
          price
          guest_tickets_price
          boxoffice_price
          channels
          delivery_methods
          order_items_aggregate(where: {order: {status: {_eq: "paid"}}}) {
            aggregate {
              count
            }
          }
          event {
            end_date
            event_display_name
            image
            name
            start_date
            status
            type
          }
          product {
            product_name
            product_display_name
            product_type
            product_description
            short_product_description
            product_requirements {
              requirement {
                req_name
                req_id
              }
            }
            product_items {
              name
              product_id
              add_on_product_id
              type
              quantity
            }
            plans(order_by: {num_of_payments: asc}) {
              name
              num_of_payments
              price
              product_id
            }
            order_items_aggregate(where: {order: {status: {_eq: "paid"}}}) {
              aggregate {
                count
              }
            }
          }
        }
      }
    }`,
        variables: orgId
    }
    return makeRequest(query)
}

GraphqlQueries.getCrewEventsPicker = (data) => {
    const query = {
        operationName: 'get_events_by_org',
        query: `query get_events_by_org($org_id: Int = ${Vue.prototype.$org.org_id}, $event_status: String) {
      events(where: {org_id: {_eq: $org_id}, type: {_is_null: true}, status: {_neq: $event_status}}, order_by: {name: asc}) {
        event_id
        name
        type
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getEventByUrlName = (eventInfo) => {
    const query = {
        operationName: 'get_event_by_urlname',
        query: `query get_event_by_urlname($url_name: String, $orgID: Int = ${Vue.prototype.$org.org_id}) {
      events(where: {url_name: {_eq: $url_name}, org_id: {_eq: $orgID}}) {
        org_id
        start_date
        end_date
        name
        image
        event_id
        status
        type
      }
    }`,
        variables: eventInfo
    }
    return makeRequest(query)
}

GraphqlQueries.getEventById = async(eventID) => {
    const query = {
        operationName: 'get_event',
        query: `query get_event($event_id: Int, $_eq1: String = "active") {
      events(where: {event_id: {_eq: $event_id}}) {
        end_date
        event_id
        event_display_name
        event_display_name_2
        name
        image
        info_file
        info_modal
        org_id
        public_page_url
        schedule_modal
        schedule_file
        start_date
        status
        type
        url_name
        products(where: {status: {_eq: $_eq1}}) {
          price
          event_id
          org_id
          product_id
          product_name
          status
          product_description
          product_type
          quantity
          stripe_id
          stripe_price_id
          req_id
          requirement {
            req_id
            req_name
          }
          product_items {
            product_items_id
            name
            quantity
            product_id
            type
          }
        }
        organization {
          org_id
          org_name
          application_fee_amount
          status
          stripe_id
        }
        venue {
          address_1
          address_2
          city
          country
          phone_number
          postal_code
          state_province
          venue_id
          venue_name
          website
        }
      }
    }`,
        variables: eventID
    }
    return await makeRequest(query)
}

GraphqlQueries.getProducts = () => {
    const query = {
        operationName: 'get_products',
        query: `query get_products{
      products(where: {org_id: {_eq: ${Vue.prototype.$org.org_id}}}) {
        product_name
        product_type
        req_id
        price
        product_id
        product_items {
          quantity
        }
        stripe_id
        stripe_price_id
      }
  }`
    }
    return makeRequest(query)
}

GraphqlQueries.checkInventoryLevelByProduct = (data) => {
    const query = {
        operationName: 'checkInventoryLevelByProduct',
        query: `query checkInventoryLevelByProduct($eventID: Int, $eventProductId: Int) {
      event_products(where: {event_id: {_eq: $eventID}, event_products_id: {_eq: $eventProductId}}) {
        quantity
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getProductsByType = (data) => {
    const query = {
        operationName: 'event_products_by_type',
        query: `query event_products_by_type($product_type: String, $event_id: Int, $status: String = "active", $_contains: jsonb = "online", $_eq: String = "active") {
      event_products(where: {product: {product_type: {_eq: $product_type}}, event_id: {_eq: $event_id}, channels: {_contains: $_contains}, status: {_eq: $status}}) {
        event_id
        event_products_id
        price
        channels
        display_order
        product_id
        display_to_team_type
        display_to_team_sub_type
        product {
          add_ons
          created_at
          delivery
          display_image_product_card
          display_image_product_detail
          product_id
          product_description
          product_name
          product_display_name
          product_group
          quantity
          req_id
          requirements
          short_product_description
          status
          updated_at
        }
        hard_card_levels {
          hard_card_level_id
          is_media
          layout
          level_display_name
          level_name
          org_id
          status
          type
        }
      }
    }
    `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getProductById = (productInfo) => {
    const query = {
        operationName: 'get_products',
        query: `query get_products($product_id: Int) {
      products(where: {product_id: {_eq: $product_id}}) {
        product_name
        status
        price
        event_id
        product_description
        product_display_name
        short_product_description
      }
    }`,
        variables: productInfo
    }
    return makeRequest(query)
}

GraphqlQueries.getCredentials = (data) => {
    const query = {
        operationName: 'event_products',
        query: `query event_products($org_id: Int = ${Vue.prototype.$org.org_id}, $status: String = "active") {
      event_products(where: {product: {org_id: {_eq: $org_id}}, status: {_eq: $status}}, order_by: {product: {product_name: asc}}) {
        event_products_id
        display_to_team_sub_type
        display_to_team_type
        groups
        guest_ticket_fees
        tags
        event{
          status
        }
        channels
        price
        hard_card_levels {
          is_temp
          level_name
        }
        product {
          product_id
          product_name
          product_type
          product_display_name
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getEventProductById = (productType) => {
    const query = {
        operationName: 'event_products',
        query: `query event_products($event_products_id: Int!) {
          event_products : event_products_by_pk(event_products_id: $event_products_id) {
            accounting_code
            boxoffice_price
            delivery_methods
            display_image_product_card
            display_image_product_detail
            display_order
            display_public
            channels
            event {
              end_date
              event_display_name
              event_display_name_2
              name
              status
              start_date
            }
            event_products_id
            event_id
            guest_tickets_price
            groups
            is_variant
            max_per_order
            max_per_person
            max_per_team
            max_quantity
            min_per_order
            more_info
            override_description
            override_product_name
            override_short_description
            price
            product {
              accounting_code
              created_at
              display_image_product_card
              display_image_product_detail
              display_image_product_shopping_cart
              is_admission
              is_driver_reg
              is_hardcard
              is_media
              org_id
              product_display_name
              product_description
              product_group
              product_id
              product_name
              product_type
              price
              quantity
              short_product_description
              status
              tags
              transferable
              updated_at
            }
            product_id
            quantity
            reporting_tags
            restrict_to_group
            seatsio
            status
            tags
            taxes
            transferable
            variant_display_name
            variants
            visibility_option
          }
        }`,
        variables: productType
    }
    return makeRequest(query)
}

GraphqlQueries.getCrewEventProducts = (data) => {
    const query = {
        operationName: 'event_products',
        query: `query event_products($org_id: Int = ${Vue.prototype.$org.org_id}, $channel: jsonb = "crew", $team_sub_type: jsonb, $status: String = "active", $product_type: String) {
      event_products(where: {product: {org_id: {_eq: $org_id}, product_type: {_neq: $product_type}}, channels: {_contains: $channel}, display_to_team_sub_type: {_contains: $team_sub_type}, status: {_eq: $status}}, order_by: {product: {product_name: asc}}) {
        event_products_id
        display_to_team_sub_type
        display_to_team_type
        groups
        guest_ticket_fees
        tags
        price
        channels
        hard_card_levels {
          is_temp
          level_name
        }
        product {
          product_id
          product_name
          product_type
          product_description
          product_display_name
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

/* {
  "org_id": 1,
  "product_type": "Registration"
} */
GraphqlQueries.getProductsByEvent = (productType) => {
    const query = {
        operationName: 'event_products',
        query: `query event_products($org_id: Int = ${Vue.prototype.$org.org_id}, $event_id: Int) {
      event_products(where: {event: {org_id: {_eq: $org_id}, _and: {event_id: {_eq: $event_id}}}, status: {_eq: "active"}, channel: {_eq: "online"}}, order_by: {display_order: asc}) {
        product_id
        quantity
        status
        price
        product {
          product_name
          product_display_name
          product_type
          product_description
          product_requirements {
            requirement {
              req_name
              req_id
            }
          }
          product_items {
            name
            product_id
            add_on_product_id
            type
            quantity
          }
          plans(order_by: {num_of_payments: asc}) {
            name
            num_of_payments
            price
            product_id
          }
        }
      }
    }`,
        variables: productType
    }
    return makeRequest(query)
}

GraphqlQueries.getProductsByEventID = (productType) => {
    const query = {
        operationName: 'event_products',
        query: `query event_products($org_id: Int = ${Vue.prototype.$org.org_id}, $event_id: Int) {
      event_products(where: {event: {org_id: {_eq: $org_id}, _and: {event_id: {_eq: $event_id}}}}, order_by: {display_order: asc}) {
        event_products_id
        access_start
        access_end
        checkin_info
        count_oi
        display_image_product_card
        display_image_product_detail
        display_order
        display_to_team_type
        display_to_team_sub_type
        fee_1
        fee_2
        fee_3
        fee_4
        fee_5
        absorb_fee_1
        absorb_fee_2
        absorb_fee_3
        absorb_fee_4
        absorb_fee_5
        product_id
        quantity
        max_quantity
        max_per_order
        max_per_team
        status
        price
        guest_tickets_price
        boxoffice_price
        channels
        delivery_methods
        restrict_to_group
        groups
        sale_start_dttm
        sale_end_dttm
        display_start_date
        display_end_date
        override_product_name
        override_description
        override_short_description
        transferable
        variant_display_name
        order_items_aggregate(where: {order: {status: {_eq: "paid"}}}) {
          aggregate {
            count
          }
        }
        event {
          end_date
          event_display_name
          image
          name
          public_page_url
          start_date
          status
          type
        }
        product {
          default_location
          display_image_product_card
          display_image_product_detail
          is_admission
          is_media
          is_wristband
          is_hardcard
          transferable
          product_name
          product_display_name
          product_type
          product_description
          short_product_description
          product_requirements {
            requirement {
              req_name
              req_id
            }
          }
          product_items {
            name
            product_id
            add_on_product_id
            type
            quantity
          }
          plans(order_by: {num_of_payments: asc}) {
            name
            num_of_payments
            price
            product_id
          }
          order_items_aggregate(where: {order: {status: {_eq: "paid"}}}) {
            aggregate {
              count
            }
          }
        }
      }
    }`,
        variables: productType
    }
    return makeRequest(query)
}

GraphqlQueries.getEventProductsLikeName = (data) => {
    const query = {
        operationName: 'event_products',
        query: `query event_products($_ilike: String = "%temporary%") {
      event_products(where: {product: {product_name: {_ilike: $_ilike}}}, order_by: {event: {name: asc}}) {
        event {
          event_id
          name
        }
        price
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getQuantitySoldByProduct = (productInfo) => {
    const query = {
        operationName: 'orders',
        query: `query orders($product_id: Int, $event_id: Int) {
      results : order_items_aggregate(where: {product_id: {_eq: $product_id}, product: {event_id: {_eq: $event_id}}, order: {status: {_eq: "paid"}}}) {
        order_items : aggregate{
          total_items : count
        }
      }
    }`,
        variables: productInfo
    }
    return makeRequest(query)
}

GraphqlQueries.getProductRequirementsByProductId = (productId) => {
    const query = {
        operationName: 'product_requirements',
        query: `query product_requirements($product_id: Int) {
      product_requirements(where: {product_id: {_eq: $product_id}}) {
        req_id
        product_id
      }
    }`,
        variables: productId
    }
    return makeRequest(query)
}

GraphqlQueries.getProductFulfillablesByProductId = (productId) => {
        const query = {
            operationName: 'product_items',
            query: `query product_items($product_id: Int) {
      product_items(where: {product_id: {_eq: $product_id}}) {
        quantity
        type
        product {
          product_id
          product_name
        }
        add_on_product_id
      }
    }`,
            variables: productId
        }
        return makeRequest(query)
    }
    /*
        {
          org_id: this.$org.org_id,
          event_id:5
        }
        */
GraphqlQueries.productSubscription = (orgEvent) => {
    const query = {
        operationName: 'ProductSubscription',
        query: `subscription ProductSubscription($org_id: Int = ${Vue.prototype.$org.org_id}, $event_id: Int) {
      products(where: {org_id: {_eq: $org_id}, event_id: {_eq: $event_id}}) {
            product_name
            product_type
            req_id
            price
            quantity
            product_id
            product_items {
              quantity
            }
            stripe_id
            stripe_price_id
          }
    }`,
        variables: orgEvent
    }
    return makeRequest(query)
}

GraphqlQueries.getTeams = (data) => {
    const query = {
        operationName: 'get_teams',
        query: `query get_teams($org_id: Int = ${Vue.prototype.$org.org_id}) {
      teams(where: {org_id: {_eq: $org_id}, status: {_nin: ["delete", "deleted"]}}) {
        org_id
        status
        team_id
        team_name
        crew_access
        driver_full_name
        driver_last_name
        guest_ticket_access
        house_account_id
        multi_crew_admins
        official_team_name
        org_account_owner
        type
        sub_type
        team_facebook_url
        team_instagram_url
        team_logo
        team_sponsors
        team_twitter_url
        team_users {
          main_contact
          purchase_tickets
          request_credential
          role_id
          status
          team_id
          user_id
          user {
            cell_phone
            email
            first_name
            full_name
            headshot
            last_name
          }
        }
        house_account {
          cell_phone
          first_name
          email
          full_name
          last_name
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamUsers = (teamId) => {
    const query = {
        operationName: 'get_team_users',
        query: `query get_team_users($team_id: Int) {
      team_users(where: {team: {team_id: {_eq: $team_id}}}) {
        team_user_id
        user {
          first_name
          last_name
          full_name
          email
          cell_phone
          user_id
        }
        team_positions {
          position {
            positions_id
            position_name
          }
        }
        role {
          role_id
          role_name
        }
      }
    }`,
        variables: teamId
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamUsersByRoleId = (teamId) => {
    const query = {
        operationName: 'get_team_users',
        query: `query get_team_users($team_id: Int, $role_id: Int) {
      team_users(where: {team: {team_id: {_eq: $team_id}}, role_id: {_eq: $role_id}}) {
        user {
          first_name
          last_name
          full_name
          email
          cell_phone
          user_id
        }
        team_positions {
          position {
            positions_id
            position_name
          }
        }
        role {
          role_id
          role_name
        }
      }
    }`,
        variables: teamId
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamUserCrew = (data) => {
    const query = {
        operationName: 'teams',
        query: `query teams($team_id: Int, $user_id: Int) {
      teams(where: {team_id: {_eq: $team_id}}) {
        team_id
        team_name
        status
        org_id
        sub_type
        team_users(where: {user: {user_id: {_eq: $user_id}}}) {
          team {
            team_id
            team_name
          }
          team_user_id
          status
          purchase_tickets
          role {
            role_id
            role_name
          }
          team_positions {
            position {
              positions_id
              position_name
            }
          }
          user {
            user_id
            email
            first_name
            last_name
            full_name
            cell_phone
            status
            hardCardsByAssignedUser {
              hard_card_id
              status
              print_title
            }
            order_requirement_items {
              status
              requirement {
                req_id
                req_name
              }
              order_item {
                order {
                  status
                }
              }
            }
          }
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getPositions = (data) => {
    const query = {
        operationName: 'get_positions',
        query: `query get_positions($org_id: Int = ${Vue.prototype.$org.org_id}, $channel: jsonb) {
      positions(order_by: {position_name: asc}, where: {org_id: {_eq: $org_id}, sub_channel: {_contains: $channel}}) {
        positions_id
        position_name
        req_id
        sub_channel
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamPositionByTeamUserId = (teamId) => {
    const query = {
        operationName: 'team_positions',
        query: `query team_positions($team_user_id: Int) {
      team_positions(where: {team_user_id: {_eq: $team_user_id}}) {
        position_id
        position {
          position_name
        }
      }
    }`,
        variables: teamId
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamUserByEmail = (email) => {
    const query = {
        operationName: 'get_team_users_by_email',
        query: `query get_team_users_by_email($email: String, $team_id: Int) {
      team_users(where: {user: {email: {_eq: $email}}, team_id: {_eq: $team_id}}) {
        team_id
        team_user_id
      }
    }`,
        variables: email
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamByUser = (data) => {
    const query = {
        operationName: 'get_team_by_user',
        query: `query get_team_by_user($user_id: Int) {
      team_users(where: {user_id: {_eq: $user_id}}) {
        team_id
        purchase_tickets
        request_credential
        team_user_id
        team {
          team_name
          status
          guest_ticket_access
        }
        team_positions {
          position {
            position_name
          }
          position_id
        }
        role {
          role_id
          role_name
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamByType = (data) => {
    const query = {
        operationName: 'get_team_by_type',
        query: `query get_team_by_type($type: String, $org_id: Int = ${Vue.prototype.$org.org_id}) {
      teams(where: {org_id: {_eq: $org_id }, type: {_eq: $type}, is_test_data: {_eq: false}}) {
        status
        guest_ticket_access
        crew_access
        sub_type
        team_id
        team_name
        type
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamByUserNew = (data) => {
    const query = {
        operationName: 'get_teams_by_user',
        query: `query get_teams_by_user($user_id: Int, $status: String = "active") {
          teams(where: {status: {_eq: $status}, team_users: {user_id: {_eq: $user_id}}}, order_by: {team_name: asc}) {
            team_id
                  team_name
                  official_team_name
                  status
                  crew_access
                  guest_ticket_access
                  org_id
                  type
                  organization {
                    org_name
                    org_logo
                    subdomain
                  }
                  sub_type
                  team_users {
                    team {
                      team_id
                      team_name
                    }
                    team_user_id
                    user_id
                    status
                    purchase_tickets
                    primary_position
                    primary_position_r {
                      positions_id
                      position_name
                    }
                    role {
                      role_id
                      role_name
                    }
                    role_id
                    team_positions {
                      position {
                        positions_id
                        position_name
                      }
                    }
                    user {
                      user_id
                      email
                      first_name
                      last_name
                      full_name
                      cell_phone
                      status
                      profile_photo
                      headshot
                      hardCardsByAssignedUser(where: {status: {_neq: "unpaid"}}) {
                        hard_card_id
                        status
                        print_title
                        team {
                          team_id
                        }
                      }
                      order_requirement_items {
                        status
                        requirement {
                          req_id
                          req_name
                        }
                        order_item {
                          order {
                            status
                          }
                        }
                      }
                    }
                  }
                  hard_cards(where: {status: {_neq: "unpaid"}}) {
                    hard_card_id
                    hard_card_type
                    assigned_user
                    status
                    user_id
                  }
                }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamByName = (data) => {
    const query = {
        operationName: 'get_team_by_name',
        query: `query get_team_by_name( $team_name: String ) {
      teams(where: {team_name: {_ilike: $team_name}}) {
        team_name
        team_id
        status
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamUsersByTeamAndUser = (data) => {
    const query = {
        operationName: 'team_users',
        query: `query team_users($team_id: Int, $user_id: Int) {
      team_users(where: {team_id: {_eq: $team_id}, _and: {user: {user_id: {_eq: $user_id}}}}) {
        team_user_id
        role_id
        status
        role {
          role_id
          role_name
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.checkIfAssigned = (data) => {
    const query = {
        operationName: 'team_positions',
        query: `query team_positions($position_id: Int, $team_user_id: Int) {
      team_positions(where: {position_id: {_eq: $position_id}, _and: {team_user_id: {_eq: $team_user_id}}}) {
        position_id
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getDriverProfilesAndVechicles = (data) => {
    const query = {
        operationName: 'getDriverProfilesAndVechicles',
        query: `query getDriverProfilesAndVechicles($team_id: Int) {
      driver_profiles(where: {team_user: {team_id: {_eq: $team_id}}}) {
        driver_id
        status
        team_user {
          team {
            team_name
          }
          user {
            email
            full_name
          }
        }
        vehicles {
          year
          make
          model
          engine
          vehicle_id
          status
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getVehicleByTeam = (data) => {
    const query = {
        operationName: 'get_team_drivers',
        query: `query MyQuery($team_id: Int) {
      driver_profiles(where: {team_user: {team_id: {_eq: $team_id}}}) {
        driver_id
        team_user {
          team {
            team_name
          }
        }
        vehicles {
          year
          make
          model
          engine
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getVehicleByVehicleNumber = (data) => {
    const query = {
        operationName: 'get_vehicles',
        query: `query get_vehicles($vehicle_number: Int) {
      vehicles(where: {official_vehicle_number: {_eq: $vehicle_number}}) {
        engine_manufacturer
        exterior
        horsepower
        interior
        make
        model
        official_car_name
        official_vehicle_number
        suspension
        tire_manufacturer
        vehicle_photos
        vehicle_sponsors
        year
        vehicle_alias
        vehicle_id
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOrgTeams = (orgId) => {
    const query = {
        operationName: 'get_org_teams',
        query: `query get_org_teams($org_id: Int = ${Vue.prototype.$org.org_id}) {
      organization(where: {org_id: {_eq: $org_id}}) {
        org_id
        org_name
        teams {
          team_id
          team_name
          team_users {
            team_user_id
            team_user_roles {
              role {
                role_name
              }
            }
            user {
              email
              first_name
            }
            driver_profiles {
              team_user_id
              status
              nationality
              languages
              home_town
              driver_profile_id
              current_residence
              bio
            }
          }
          team_vehicles {
            vehicle {
              vehicle_id
              vehicle_alias
              year
              make
              model
              engine
            }
          }
        }
      }
    }`,
        variables: orgId
    }
    return makeRequest(query)
}

GraphqlQueries.userSearch = (searchParams) => {
    const query = {
        operationName: 'user_search',
        query: `query user_search($email: String, $full_name: String) {
        users(where: {_or: [{email: {_ilike: $email}}, {full_name: {_ilike: $full_name}}]}) {
          user_id
          email
          full_name
        }
      }`,
        variables: searchParams
    }
    return makeRequest(query)
}

// TODO: we should use user_id instead of email
GraphqlQueries.getLoggedInUserInfo = async(email) => {
    const query = {
        operationName: 'getLoggedInUserInfo',
        query: `query getLoggedInUserInfo($email: String) {
      users(where: {email: {_eq: $email}}) {
        address_1
        address_2
        state_province
        postal_code
        birthday
        cell_phone
        city
        country
        cre_dttm
        special_sauce
        email
        first_name
        full_name
        stripe_cus_id
        profile_photo
        headshot
        is_house_account
        last_name
        media_outlet_users(where: {status: {_eq: "active"}}) {
          outlet_user_id
          role_id
          media_outlet {
            media_outlet_id
            outlet_name
            status
          }
        }
        org_users {
          org_user_id
          org_id
          role_id
          organization {
            org_name
            subdomain
            org_id
            status
          }
        }
        team_users(where: {status: {_eq: "active"}, team: {status: {_eq: "active"}}}) {
          role_id
          team_id
          status
          purchase_tickets
          request_credential
          team {
            team_name
            team_id
            status
            organization {
              org_name
              org_id
              subdomain
              status
            }
            org_id
            crew_access
            guest_ticket_access
            type
            sub_type
          }
        }
        user_id
        updated_dttm
        user_groups {
          group_id
        }
      }
    }
    `,
        variables: email
    }
    return makeRequest(query)
}

GraphqlQueries.getUserByEmail = async(email) => {
    email.email = `${email.email}`
    const query = {
        operationName: 'get_user',
        query: `query get_user($email: String) {
      users(where: {email: {_ilike: $email}}) {
        first_name
        last_name
        full_name
        email
        country
        city
        cell_phone
        headshot
        address_1
        address_2
        cre_dttm
        preferred_name
        profile_photo
        state_province
        status
        user_id
      }
    }`,
        variables: email
    }
    return makeRequest(query)
}

GraphqlQueries.getUser = (userId) => {
    const query = {
        operationName: 'get_user',
        query: `query get_user($user_id: Int) {
      users(where: {user_id: {_eq: $user_id}}) {
        full_name
        email
        country
        city
        cell_phone
        address_1
        address_2
        cre_dttm
        preferred_name
        profile_photo
        state_province
        status
        user_id
      }
    }`,
        variables: userId
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamUserIdByEmail = (userEmail) => {
    const query = {
        operationName: 'get_user',
        query: `query team_users($email: String, $_eq: String = "driver") {
      team_users(where: {user: {email: {_eq: $email}}}) {
        team_user_id
        team_user_roles(where: {role: {role_name: {_eq: $_eq}}}) {
          role {
            role_id
            role_name
          }
        }
      }
    }`,
        variables: userEmail
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamUsersByTeamAndUserId = (data) => {
    const query = {
        operationName: 'team_users',
        query: `query team_users($team_id: Int, $user_id: Int) {
      team_users(where: {team_id: {_eq: $team_id}, _and: {user_id: {_eq: $user_id}, _and: {role_id: {_neq: 5}}}}) {
        team_user_id
        status
        user_id
        role_id
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamUsersByTeamUserIdAndRoleId = (data) => {
    const query = {
        operationName: 'team_users',
        query: `query team_users($team_id: Int, $user_id: Int, $role_id: Int) {
      team_users(where: {team_id: {_eq: $team_id}, _and: {user_id: {_eq: $user_id}}, role_id: {_eq: $role_id}}) {
        team_user_id
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getUserOrg = (userId) => {
    const query = {
        operationName: 'get_user_org',
        query: `query get_user_org($user_id: Int) {
      org_users(where: {user_id: {_eq: $user_id}}) {
      organization {
      org_id
      org_name
      status
      stripe_id
      application_fee_amount
        }
      }
    }`,
        variables: userId
    }
    return makeRequest(query)
}

GraphqlQueries.getHardCardLevels = (data) => {
    const query = {
        operationName: 'hard_card_levels',
        query: `query hard_card_levels($org_id: Int = ${Vue.prototype.$org.org_id}) {
      hard_card_levels(where: { org_id: {_eq: $org_id}}) {
        back_image
        color
        duration
        event_product_id
        format
        front_image
        is_media
        layout
        type
        level_display_name
        hard_card_level_id
        event_products
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getHardCardByOrg = (data) => {
    const query = {
        operationName: 'get_hard_cards',
        query: `query get_hard_cards($org_id: Int = ${Vue.prototype.$org.org_id}, $postition_id: Int = 1, $product_type: String = "Credential") {
      hard_cards(where: {org_id: {_eq: $org_id}, order_item: {product: {product_type: {_eq: $product_type}}}}) {
        hard_card_id
        status
        hard_card_type
        print_title
        updated_at
        userByAssignedUser {
          user_id
          first_name
          last_name
          full_name
        }
        expiration
        team {
          team_id
          team_users(where: {team_position: {position_id: {_eq: $postition_id}}}) {
            team_user_id
            user {
              first_name
              last_name
            }
          }
        }
        order_item {
          order_items_id
          order_requirement_items {
            order_requirement_items_id
            status
            requirement {
              req_name
            }
          }
          order_fulfillment_items {
            product_id
            quantity
          }
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getHardCardByTeam = (data) => {
    const query = {
        operationName: 'hard_cards',
        query: `query hard_cards($team_id: Int) {
      hard_cards(where: {team_id: {_eq: $team_id}}) {
        team_id
        status
        hard_card_id
        hard_card_type
        userByAssignedUser {
          first_name
          last_name
          full_name
          user_id
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getHardCardByHardCardId = (data) => {
    const query = {
        operationName: 'get_hard_card_by_id',
        query: `query get_hard_card_by_id($hard_card_id: Int) {
      hard_cards(where: {hard_card_id: {_eq: $hard_card_id}}) {
        hard_card_id
        status
        hard_card_type
        print_title
        print_date
        format
        updated_at
        userByAssignedUser {
          user_id
          first_name
          last_name
          email
          cell_phone
          full_name
        }
        headshot_image
        front_image
        back_image
        expiration
        uuid
        team {
          team_id
          team_name
        }
        order_item {
          status
          order_items_id
          order_requirement_items {
            requirement {
              req_id
              req_name
            }
            status
          }
          order_fulfillment_items {
            product_id
            quantity
            status
            fulfilled_quantity
          }
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getCredentialTemplates = (data) => {
    const query = {
        operationName: 'credential_templates',
        query: `query credential_templates($org_id: Int = ${Vue.prototype.$org.org_id}) {
      credential_templates(where: {org_id: {_eq: $org_id}}, order_by: {template_name: asc}) {
        credential_template_id
        template_name
        template_url
        org_id
        color
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getUnassignedHardCardsByTeam = (data) => {
    const query = {
        operationName: 'get_unassigned_hard_cards',
        query: `
    `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getVehicleByAlias = (alias) => {
    const query = {
        operationName: 'vehicles',
        query: `query vehicles($vehicle_alias: String) {
      vehicles(where: {vehicle_alias: {_eq: $vehicle_alias}}) {
        engine
        make
        model
        suspension
        tires
        vehicle_alias
        vehicle_id
        year
      }
    }`,
        variables: alias
    }
    return makeRequest(query)
}

GraphqlQueries.getSeatsIOInfo = (sio) => {
    const query = {
        operationName: 'get_sio_info',
        query: `query get_sio_info($event_product_id: Int) {
            seatsio_event_products(where: {event_product_id: {_eq: $event_product_id}}) {
              category_mapping
              max_qty
              sio_channels
              sio_chartkey
              sio_ep_id
              sio_event_id
              sio_workspacekey
              th_channel
              ticket_types
              type
              use_channels
              multiple_events
              configs {
                event_id
                seatsio_config_id
                sio_event_key
                type
                type_value
              }
            }
        }`,
        variables: sio
    }
    return makeRequest(query)
}

GraphqlQueries.getGuestTickets = (guestTicketData) => {
    const query = {
        operationName: 'guest_tickets',
        query: `query guest_tickets($event_id: [Int!], $user_id: [Int!], $team_id: Int) {
      order_items(where: {user_id: {_in: $user_id}, event_product: {event_id: {_in: $event_id}, product: {is_hardcard: {_eq: false}}}, order: {status: {_eq: "paid"}, team_id: {_eq: $team_id}}, status: {_neq: "canceled"}}) {
        order_items_id
        order_number
        allocation_id
        purchaser_can_transfer
        attendee_can_transfer
        status
        assigned: assigned_user {
          full_name
          email
          first_name
          last_name
          cell_phone
          is_house_account
          user_id
          team_users {
            team_id
            role_id
            role {
              role_name
              role_description
              req_id
            }
          }
        }
        purchaser: user {
          email
          full_name
          first_name
          is_house_account
          last_name
          user_id
          team_users {
            team {
              team_name
              house_account_id
              status
              team_facebook_url
              team_instagram_url
              team_logo
              team_sponsors
              team_twitter_url
              team_website
              team_youtube_url
            }
            role_id
            role {
              role_name
              role_description
              req_id
            }
          }
        }
        product {
          is_hardcard
          product_description
          product_display_name
          product_type
          product_id
          product_requirements {
            req_id
            product_requirements_id
            requirement {
              req__display_name
              req_description
              req_html
              req_id
              req_name
            }
          }
          short_product_description
        }
        event_product {
          event_id
          channels
          event {
            name
            start_date
            end_date
            event_id
            season {
              season_display_name
            }
          }
          display_start_date
          event_products_id
          override_description
          override_product_name
          override_short_description
          seatsio
          variant_display_name
        }
        order_requirement_items {
          status
        }
        order_requirement_items_aggregate {
          aggregate {
            count(columns: status)
          }
        }
        order {
          team {
            team_name
          }
        }
        seatsio_order_item {
          sio_seat_id
          sio_oi_id
          holdToken
        }
      }
    }`,
        variables: guestTicketData
    }
    return makeRequest(query)
}

GraphqlQueries.getNavByOrg = (data) => {
    const query = {
        operationName: 'getNavByOrg',
        query: `query getNavByOrg($org_id: Int = ${Vue.prototype.$org.org_id}) {
      nav_sidebar(where: {org_id: {_eq: $org_id}, status: {_eq: "active"}}, order_by: {display_order: asc}) {
        display_order
        has_icon
        icon
        is_parent
        icon_pack
        is_org
        nav_label
        route_params
        route_name
        check_guest_tickets_access
        check_crew_page_access
        nav_sidebar_id
        nest_under
        org_id
        qs_dashboard_id
        show_coming_soon
        status
        url
        visible_team_sub_type
        visible_team_type
        visible_to_role
      }
    }
    `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getWristbands = (data) => {
    const query = {
        operationName: 'event_products',
        query: `query event_products($event_id: [Int!], $product_id: Int) {
      event_products(where: {event_id: {_in: $event_id}, product_id: {_eq: $product_id}, status: {_eq: "active"}}) {
        event_products_id
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getWillCallOrders = (data) => {
    const query = {
        operationName: 'willcall_orders',
        query: `query willcall_orders($event_id: Int) {
          order_items(where: {order: {status: {_eq: "paid"}}, event_product: {event_id: {_eq: $event_id}}}) {
            assigned_user {
              user_id
              email
              full_name
              cell_phone
            }
            user {
              user_id
              email
              full_name
              team_users {
                team {
                  team_name
                  team_users(where: {role: {role_id: {_eq: 2}}}) {
                    user {
                      driver: full_name
                    }
                  }
                }
              }
              cell_phone
            }
            status
            order_items_uuid
            check_in_note
            event_product {
              access_start
              access_end
              channels
              variant_display_name
            }
            order {
              company
              team_id
              team {
                team_name
              }
              order_create_dttm
              source_channel
              userByAllocatedBy {
                first_name
                full_name
                email
                cell_phone
                last_name
              }
            }
            order_number
            order_items_id
            product {
              product_display_name
              product_type
            }
            team {
              team_name
              team_users(where: {role: {role_id: {_eq: 2}}}) {
                user {
                  driver: full_name
                }
              }
            }
            notes
            source_channel
            seatsio_order_item {
              sio_seat_id
            }
            hard_cards {
              duplex_print
              hard_card_id
            }
            media_status
            wristband_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getCrewMemberhardCardDetail = (data) => {
    const query = {
        operationName: 'get_crew_member_hard_card_detail',
        query: `query get_crew_member_hard_card_detail($user_id: Int, $team_id: Int) {
      hard_cards(where: {assigned_user: {_eq: $user_id}, _and: {status: {_neq: "unpaid"}}, team_id: {_eq: $team_id}}) {
        hard_card_id
        duplex_print
        front_image
        status
        expiration
        hard_card_level {
          hard_card_level_id
          level_display_name
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getCrewMemberDetail = (data) => {
    const query = {
        operationName: 'get_crew_member_detail',
        query: `query get_crew_member_detail($user_id: Int,$team_id: Int) {
      users(where: {user_id: {_eq: $user_id}}) {
        cell_phone
        email
        first_name
        full_name
        last_name
        status
        user_id
        headshot
        team_users(where: {team_id: {_eq: $team_id}, role_id: {_nin: [5, 2]}}) {
          team_user_id
          team_id
          role_id
          user_id
          status
          primary_position_r {
            positions_id
            position_name
          }
          team {
            team_name
            org_id
          }
          role {
            role_id
            role_name
          }
          purchase_tickets
          team_positions {
            position {
              positions_id
              position_name
            }
          }
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getCrewMemberOrderItemsDetail = (data) => {
    const query = {
        operationName: 'get_crew_member_order_items_detail',
        query: `query get_crew_member_order_items_detail($user_id: Int, $org_id: Int = ${Vue.prototype.$org.org_id}) {
      order_items(where: {assigned_user_id: {_eq: $user_id}, order: {org_id: {_eq: $org_id}}, event_product: {event: {internal_use: {_eq: false}}}}, order_by: {event_product: {event: {name: asc}}}) {
        order_items_id
        status
        user_id
        team_id
        assigned_user {
          user_id
          full_name
          email
        }
        product {
          product_display_name
          product_id
          product_type
          product_group
        }
        event_product {
          event_products_id
          event_id
          event {
            name
            event_display_name
            event_display_name_2
          }
          product {
            product_display_name
            product_id
            product_type
            product_group
          }
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getMediaOrders = (data) => {
    const query = {
        operationName: 'event_products',
        query: `query event_products($event_id: [Int!]) {
      order_items(where: {order: {status: {_eq: "paid"}}, event_product: {event_id: {_in: $event_id}, product: {is_media: {_eq: true}}}}) {
        assigned_user {
          email
          full_name
        }
        user {
          email
          full_name
          team_users {
            team {
              team_name
              team_users(where: {role: {role_id: {_eq: 2}}}) {
                user {
                  driver: full_name
                }
              }
            }
          }
        }
        status
        order_items_uuid
        check_in_note
        event_product {
          access_start
          access_end
          channels
          variant_display_name
        }
        order {
          company
        }
        order_number
        order_items_id
        product {
          product_display_name
          product_type
        }
        team {
          team_name
          team_users(where: {role: {role_id: {_eq: 2}}}) {
            user {
              driver: full_name
            }
          }
        }
        notes
        source_channel
        seatsio_order_item {
          sio_seat_id
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getMediaOrders = (data) => {
    const query = {
        operationName: 'event_products',
        query: `query event_products($event_id: [Int!]) {
      order_items(where: {order: {status: {_eq: "paid"}}, event_product: {event_id: {_in: $event_id}, product: {is_media: {_eq: true}}}}) {
        assigned_user {
          email
          full_name
          cell_phone
        }
        user {
          email
          full_name
          cell_phone
          team_users {
            team {
              team_name
              team_users(where: {role: {role_id: {_eq: 2}}}) {
                user {
                  driver: full_name
                }
              }
            }
          }
        }
        media_status
        media_number
        status
        order_items_uuid
        check_in_note
        event_product {
          access_start
          access_end
          channels
          variant_display_name
        }
        order {
          company
        }
        order_number
        order_items_id
        product {
          product_display_name
          product_type
        }
        team {
          team_name
          team_users(where: {role: {role_id: {_eq: 2}}}) {
            user {
              driver: full_name
            }
          }
        }
        notes
        source_channel
        seatsio_order_item {
          sio_seat_id
        }
        order_fulfillment_items {
          media_number
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOrderItemsByProductIds = (data) => {
    const query = {
        operationName: 'orders_by_product',
        query: `query orders_by_product($product_ids: [Int!]) {
      order_items(where: {order: {status: {_eq: "paid"}}, event_product: {product: {product_id: {_in: $product_ids}}}}) {
        assigned_user {
          email
          full_name
        }
        user {
          email
          full_name
          team_users {
            team {
              team_name
              team_users(where: {role: {role_id: {_eq: 2}}}) {
                user {
                  driver: full_name
                }
              }
            }
          }
        }
        status
        order_items_uuid
        check_in_note
        event_product {
          access_start
          access_end
          channels
          variant_display_name
        }
        order {
          company
        }
        order_number
        order_items_id
        product {
          product_display_name
          product_type
        }
        team {
          team_name
          team_users(where: {role: {role_id: {_eq: 2}}}) {
            user {
              driver: full_name
            }
          }
        }
        notes
        source_channel
        seatsio_order_item {
          sio_seat_id
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getReportFDOrderDetailed = () => {
    const query = {
        operationName: 'get_fd_orders_detailed',
        query: `query get_fd_orders_detailed {
      fd_orders_detailed(where: {product_accounting_code: {_is_null: false}, stripe_payout_id: {_is_null: false}}) {
        accounting_code
        discount_amount
        event_name
        item_service_fee
        item_venue_fee
        order_items_id
        order_number
        order_total_amount
        order_total_stripe_fee_charged
        product_display_name
        product_price
        product_type
        purchaser
        sold_price
        source_channel
        total_order_customer_paid_fees
        order_item_status
        purchase_type
        order_item_source_channel
        user_id
        order_user
        assigned_user
        team_type
        team_sub_type
        order_created_dttm
        stripe_payment_id
        product_accounting_code
        event_products_id
        stripe_payout_id
        stripe_payout_date
      }
    }`
    }
    return makeRequest(query)
}

GraphqlQueries.getReportFDOrderDetailedNew = () => {
    const query = {
        operationName: 'fd_orders_detailed_new',
        query: `query fd_orders_detailed_new {
      fd_orders_detailed_new {
        accounting_code
        assigned_user
        discount_amount
        event_name
        event_products_id
        order_created_dttm
        order_item_source_channel
        order_item_status
        order_items_id
        order_number
        order_total_amount
        order_user
        product_accounting_code
        product_display_name
        product_price
        product_type
        purchase_type
        purchaser
        service_fee
        sold_price
        source_channel
        stripe_fee
        stripe_payment_id
        stripe_payout_date
        stripe_payout_id
        team_type
        total_fees
        total_item_fee
        venue_fee
        total
        deposit_amount
        oi_stripefee
      }
    }`
    }
    return makeRequest(query)
}

GraphqlQueries.getDriverProfiles = () => {
    const query = {
        operationName: 'get_driver_profiles',
        query: `query get_driver_profiles {
          driver_profiles {
            bio
            birthday
            current_city
            current_country
            current_state
            driver_facebook_url
            driver_headshot
            driver_instagram_url
            driver_twitter_url
            driver_website_url
            driver_youtube_url
            emergency_contact_mobile
            emergency_contact_name
            emergency_contact_relationship
            first_name
            hometown_city
            hometown_country
            hometown_state
            languages_spoken
            last_name
            nationality
            org_id
            status
            team_user_id
            driver_profile_id
            team_user {
              team {
                team_name
                vehicle_number
                team_id
                sub_type
                vehicle {
                  engine_manufacturer
                  exterior
                  horsepower
                  interior
                  make
                  model
                  official_car_name
                  official_vehicle_number
                  suspension
                  tire_manufacturer
                  vehicle_alias
                  vehicle_id
                  vehicle_photos
                  vehicle_sponsors
                  year
                }
              }
              user {
                email
                cell_phone
              }
            }
          }
        }`
    }
    return makeRequest(query)
}

GraphqlQueries.getDriversWithDetails = (data) => {
    const query = {
        operationName: 'getDriversWithDetails',
        query: `query getDriversWithDetails($org_id: Int = ${Vue.prototype.$org.org_id}) {
          team_users(where: {role_id: {_eq: 2}, team: {org_id: {_eq: $org_id}, status: {_eq: "active"}, vehicle: {status: {_eq: "active"}}}}) {
            team_user_id
            user {
              first_name
              last_name
              full_name
              user_id
            }
            driver_profiles {
              birthday
              current_city
              current_country
              current_state
              hometown_city
              hometown_country
              hometown_state
              languages_spoken
              nationality
              bio
              driver_facebook_url
              driver_headshot
              driver_instagram_url
              driver_profile_id
              driver_twitter_url
              driver_website_url
              driver_youtube_url
              emergency_contact_mobile
              emergency_contact_name
              emergency_contact_relationship
            }
            team {
              team_id
              vehicle_number
              team_name
              official_team_name
              team_logo
              sub_type
              team_tiktok_url
              team_twitter_url
              team_youtube_url
              team_website
              team_instagram_url
              team_sponsors
            }
            vehicle {
              official_vehicle_number
              official_car_name
              year
              make
              model
              engine_manufacturer
              horsepower
              exterior
              interior
              vehicle_photos
              vehicle_sponsors
              suspension
              tire_manufacturer
              vehicle_id
              vehicle_alias
            }
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getAllocations = (data) => {
    const query = {
        operationName: 'get_allocations',
        query: `query get_allocations($team_id: Int) {
      allocations(where: {team_id: {_eq: $team_id}}) {
        allocated_by
        allocation_id
        cre_dttm
        cre_user
        create_user {
          full_name
        }
        event_id
        house_account_id
        limit_event_product_id
        limit_product_id
        max_qty
        org_id
        product_type
        qty
        status
        team_id
        update_user {
          full_name
        }
        user_id
        use_event_product_id
        updated_user
        updated_dttm
        user {
          user_id
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getReaderByLabel = (data) => {
    const query = {
        operationName: 'getReaderByLabel',
        query: `query getReaderByLabel($label_number: String, $org_id: Int = ${Vue.prototype.$org.org_id}) {
    stripe_readers(where: {label: {_eq: $label_number}, org_id: {_eq: $org_id}}) {
      boxoffice_id
      label
      org_id
      reader_id
      stripe_reader_id
      is_test_mode
    }
  }
  `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getBoxOfficeTerminalStatus = (data) => {
    const query = {
        operationName: 'getBoxOfficeTerminalStatus',
        query: `query getBoxOfficeTerminalStatus($boxoffice_label: String, $org_id: Int = ${Vue.prototype.$org.org_id}) {
  box_office : boxoffice_terminals(where: {boxoffice_label: {_eq: $boxoffice_label}, org_id: {_eq: $org_id}}) {
     boxoffice_terminal_id
      closing_amount
      created_at
      opened_by
      opening_amount
      org_id
      status
      updated_dttm
      boxoffice_label
  }
}`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getAllDiscountCodesByOrg = (data) => {
    const query = {
        operationName: 'getAllDiscountCodesByOrg',
        query: `query getAllDiscountCodesByOrg($org_id: Int = ${Vue.prototype.$org.org_id}) {
        discount_codes(where: {org_id: {_eq: $org_id}}) {
          notes
          active
          channels
          code_name
          created_at
          created_by
          discount_amount
          discount_code_id
          discount_type
          modified_by
          org_id
          updated_dttm
          created_user {
            full_name
          }
          last_modified_by {
            full_name
          }
        }
      }
    `,
        variables: data
    }
    return makeRequest(query)
}
GraphqlQueries.hardCardsByOrg = (data) => {
    const query = {
        operationName: 'hardCardsByOrg',
        query: `query hardCardsByOrg($org_id: Int = ${Vue.prototype.$org.org_id}) {
      hard_cards(where: {org_id: {_eq: $org_id}}) {
        front_image
        hard_card_id
        duplex_print
        uuid
        back_image
        assignedUser: userByAssignedUser {
          full_name
          email
          headshot
          last_name
          user_id
        }
        purchaser: user {
          full_name
          user_id
        }
        team {
          team_name
          type
          team_id
          sub_type
        }
        status
        print_title
        hard_card_level {
          hard_card_level_id
          level_name
        }
        org_id
        order_items_id
        order_item {
          event_product {
            event {
              event_display_name
            }
          }
          order_number
          product {
            product_display_name
          }
          source_channel
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

// Team detail Page Query
GraphqlQueries.getTeamDetail = (data) => {
    const query = {
        operationName: 'teamDetail',
        query: `query teamDetail($team_id: Int, $org_id: Int = ${Vue.prototype.$org.org_id}) {
      teams(where: {team_id: {_eq: $team_id}, org_id: {_eq: $org_id}}) {
        team_id
        status
        team_logo
        team_name
        type
        sub_type
        house_account_id
        house_account {
          cell_phone
          first_name
          email
          full_name
          last_name
        }
        org_id
        organization {
          org_name
          org_logo
          org_logo_square
        }
        guest_ticket_access
        crew_access
        multi_crew_admins
        org_account_owner
        team_facebook_url
        team_instagram_url
        team_twitter_url
        team_youtube_url
        team_website
        team_sponsors
        team_users (where: {team_id: {_eq: $team_id}}) {
          purchase_tickets
          main_contact
          status
          user {
            user_id
            first_name
            last_name
            full_name
            email
            cell_phone
            cre_dttm
          }
          role {
            role_id
            role_name
          }
          team_position {
            position {
              position_name
              positions_id
            }
          }
        }
      }
      addresses(where: {team_id: {_eq: $team_id}}) {
        address_id
        type
        address_1
        address_2
        city
        country
        postal_code
        state_province
        phone
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

// Team Detail Page - for main contact list modal
GraphqlQueries.getTeamUserList = (data) => {
    const query = {
        operationName: 'getTeamUser_List',
        query: `query getTeamUser_List($team_id: Int) {
        team_users (where: {team_id: {_eq: $team_id}}) {
          team_user_id
          purchase_tickets
          main_contact
          role_id
          status
          user {
            user_id
            first_name
            last_name
            full_name
            email
            cell_phone
            cre_dttm
          }
        }

    }`,
        variables: data
    }
    return makeRequest(query)
}

// Team Detail Page - Fetching main contact user (If already there!)
GraphqlQueries.getTeamDetailMainContact = (data) => {
    const query = {
        operationName: 'teamDetailMainContact',
        query: `query teamDetailMainContact($team_id: Int) {
        team_users (where: {main_contact: {_eq: true}, team_id: {_eq: $team_id}}) {
          team_user_id
          main_contact
          status
          user {
            user_id
            profile_photo
            headshot
            full_name
            email
            cell_phone
          }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

// Team detail -> for Crew Table Query
GraphqlQueries.getTeamUsersDetail = (data) => {
    const query = {
        operationName: 'teamUsersDetails',
        query: `query teamUsersDetails($team_id: Int) {
      team_users(where: {team_id: {_eq: $team_id}, role_id: {_gte: 3}, _or: {role_id: {_lte: 4}}}) {
          team_user_id
          team_id
          status
          primary_position : primary_position_r{
            position_name
          }
          purchase_tickets
          user {
            user_id
            full_name
            first_name
            last_name
            profile_photo
            headshot
            status
            email
            cell_phone
            hardCardsByAssignedUser (where: {team_id: {_eq: $team_id}}, order_by: {created_dttm: desc_nulls_last}) {
              status
              front_image
              team_id
              org_id
            }
          }
          role {
            role_id
            role_name
          }
          team_position {
            position {
              position_name
              positions_id
            }
          }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

// Team detail -> for Guest Ticket Table Query
GraphqlQueries.guestTicketUsersDetails = (data) => {
    const query = {
        operationName: 'guestTicketUsersDetails',
        query: `query guestTicketUsersDetails($team_id: Int) {
      order_items(where: {team_id: {_eq: $team_id}}) {
        source_channel
        status
        user {
          full_name
          first_name
          last_name
        }
        product {
          product_type
          product_display_name
        }
        event_product {
          event {
            name
          }
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

// Team detail -> for Org_users
GraphqlQueries.getTeamTableOrgUsers = (data) => {
    const query = {
        operationName: 'teamTableOrgUsers',
        query: `query teamTableOrgUsers ($org_id: Int = ${Vue.prototype.$org.org_id}) {
      org_users (where: {org_id: {_eq: $org_id}, account_manager: {_eq: true}}) {
        org_id
        user {
          user_id
          full_name
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

// Team detail -> for Org_user
GraphqlQueries.getOrgUser = (data) => {
    const query = {
        operationName: 'OrgUser',
        query: `query OrgUser ($org_account_owner: Int) {
      users(where: {user_id: {_eq: $org_account_owner}}) {
        cell_phone
        full_name
        email
        profile_photo
        headshot
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

// Team detail -> for Team Positions
GraphqlQueries.getPositionsCrewTab = (data) => {
    const query = {
        operationName: 'PositionsCrewTab',
        query: `query PositionsCrewTab($sub_channel: jsonb) {
      positions(order_by: {position_name: asc}, where: { sub_channel: {_contains: $sub_channel}}) {
        positions_id
        position_name
        sub_channel
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

// Team detail -> for Events data
GraphqlQueries.getEventsGuestTab = (data) => {
    const query = {
        operationName: 'EventsGuestTab',
        query: `query EventsGuestTab ($org_id: Int = ${Vue.prototype.$org.org_id}) {
      events (where: {org_id: {_eq: $org_id}}) {
        name
        event_id
        event_display_name
        event_display_name_2
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getEventsByOrgSimple = (data) => {
    const query = {
        operationName: 'orgEvents',
        query: `query orgEvents ($org_id: Int = ${Vue.prototype.$org.org_id}) {
        events(where: {org_id: {_eq: $org_id}}) {
          name
          event_id
          event_display_name
          event_display_name_2
          start_date
          end_date
          url_name
          venue_id
          type
          timezone
          ticket_release_dttm
          taxable
          status
          signup_confirmation_image
          shipping_cutoff_dttm
          ship_dttm
          org_id
          internal_use
          image
          house_account_id
          event_notices
          display_channel
          default_product_image
          schedule_file
          schedule_modal
          info_file
          info_modal
          venue {
            venue_id
            venue_name
            city
            state_province
            postal_code
          }
        }
      }`,
        variables: data
    }
    return makeRequest(query)
}

// Team detail -> for Teams (Partner Name)
GraphqlQueries.getTeamsGuestTab = () => {
    const query = {
        operationName: 'TeamsGuestTab',
        query: `query TeamsGuestTab {
      teams {
        team_name
        team_id
      }
    }`
    }
    return makeRequest(query)
}

// Team detail -> for Fetching Team page logo and brand guide
GraphqlQueries.getTeamsLogoAndBrandGuide = (data) => {
    const query = {
        operationName: 'teamsLogoAndBrandGuide',
        query: `query teamsLogoAndBrandGuide ($team_id: Int) {
      files(where: {team_id: {_eq: $team_id}}) {
        file_id
        file_display_name
        file_description
        org_id
        team_id
        uploaded_by
        user_id
        url
        updated_dttm
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getDiscountCodesByOrgAndChannel = (data) => {
    const query = {
        operationName: 'getDiscountCodesByOrgAndChannel',
        query: `query getDiscountCodesByOrgAndChannel($org_id: Int = ${Vue.prototype.$org.org_id}, $channel: jsonb = "") {
      discount_codes(where: {org_id: {_eq: $org_id}, active: {_eq: true}, channels: {_contains: $channel}}) {
        notes
        active
        channels
        code_name
        display_name
        created_at
        created_by
        discount_amount
        discount_code_id
        discount_type
        modified_by
        org_id
        updated_dttm
        restrictions
      }
    }
    `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getSponsorData = () => {
    const query = {
        operationName: 'sponsor_activation',
        query: `query sponsor_activation($service_id: Int = 1746990) {
      sponsor_activation(where: {service_id: {_eq: $service_id}}) {
        service_id
        wristband_id
        user {
          email
          first_name
          last_name
          city
          cell_phone
          birthday
          address_2
          address_1
        }
      }
    }`
    }
    return makeRequest(query)
}

GraphqlQueries.getEventProductsByIds = (data) => {
    const query = {
        operationName: 'getEventProductsByIds',
        query: `query getEventProductsByIds($event_product_ids: [Int!], $channel: jsonb) {
          event_products(where: {event_products_id: {_in: $event_product_ids}, channels: {_contains: [$channel]}, status: {_in: ["active"]}, event: {status: {_nin: ["draft", "past"]}}}) {
            absorb_fee_1
            absorb_fee_2
            absorb_fee_3
            absorb_fee_4
            absorb_fee_5
            absorb_fee_cc
            absorb_fee_platform
            access_end
            access_start
            accounting_code
            boxoffice_price
            channels
            checkin_info
            created_at
            delivery_methods
            discount_type
            discount_value
            display_image_product_card
            display_image_product_detail
            display_order
            display_public
            display_to_team_sub_type
            display_to_team_type
            event {
              end_date
              event_id
              event_display_name
              start_date
            }
            event_products_id
            event_id
            fee_1
            fee_2
            fee_3
            fee_4
            fee_5
            groups
            guest_tickets_price
            hide_package_description
            is_package
            is_variant
            max_per_order
            max_per_team
            max_per_person
            max_quantity
            min_per_order
            package_event_products
            photos
            product_id
            price
            package_display_name
            seatsio
            sold_out_state
            display_start_date
            status
            quantity
            tags
            taxes
            updated_at
            variant_display_name
            variants
            video_url
            visibility_option
            visibility_product_link
            product {
              accounting_code
              add_ons
              checkin_notes
              created_at
              delivery
              display_image_product_card
              display_image_product_detail
              event_id
              fill_input_type
              fulfillment_color
              fulfillment_image
              is_wristband
              is_hardcard
              product_description
              product_display_name
              product_group
              quantity
              req_id
              requirements
              scanner_db_id
              short_product_description
              status
              stripe_id
              stripe_price_id
              stripe_tax_id
              updated_at
            }
            orders : order_items_aggregate {
              aggregate {
                count
              }
            }
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getCartSessionByID = (data) => {
    const query = {
        operationName: 'getCartSessionByID',
        query: `query getCartSessionByID($id: uuid!) {
              cartSession : cart_sessions_by_pk(id: $id) {
                payload
                created_at
                user_id
                id
              }
            }
            `,
        variables: data
    }
    return makeRequest(query)
}
GraphqlQueries.getProductsAndEventProducts = (data) => {
    const query = {
        operationName: 'getProductsAndEventProducts',
        query: `query getProductsAndEventProducts($products: [Int!], $org_id: Int) {
          products(where: {product_id: {_in: $products}, org_id: {_eq: $org_id}}) {
            product_display_name
            product_group
            product_group_display_order
            product_type
            product_id
            product_type_display_order
            status
            event_products(where: {status: {_eq: "active"}}) {
              absorb_fee_1
              absorb_fee_2
              absorb_fee_3
              absorb_fee_4
              absorb_fee_5
              absorb_fee_cc
              absorb_fee_platform
              boxoffice_price
              channels
              created_at
              discount_value
              discount_type
              display_end_date
              display_image_product_card
              display_image_product_detail
              display_order
              display_public
              display_start_date
              display_to_team_sub_type
              display_to_team_type
              event_id
              event_products_id
              fee_1
              fee_2
              fee_3
              fee_4
              fee_5
              groups
              guest_tickets_price
              hide_package_description
              is_package
              is_variant
              max_per_order
              max_per_person
              max_quantity
              min_per_order
              package_display_name
              package_event_products
              override_short_description
              photos
              price
              product_id
              quantity
              restrict_to_group
              sale_end_dttm
              sale_start_dttm
              seatsio
              sold_out_state
              status
              tags
              taxes
              updated_at
              variant_display_name
              variants
              video_url
              visibility_option
              visibility_product_link
              product {
                is_wristband
                is_media
                is_admission
                is_hardcard
                display_image_product_card
                display_image_product_detail
                product_description
                product_display_name
                product_group
                product_group_display_order
                product_id
                product_name
                product_type
                product_type_display_order
                status
                short_product_description
              }
              event {
                end_date
                event_display_name
                event_display_name_2
                display_channel
                event_notices
                event_id
                house_account_id
                image
                image_square
                image_square_sold_out
                internal_use
                name
                org_id
                signup_confirmation_image
                start_date
                status
                taxable
                timezone
                type
                url_name
                venue_id
                venue {
                  address_1
                  address_2
                  city
                  country
                  display_location
                  phone_number
                  postal_code
                  state_province
                  tax_rate
                  venue_id
                  venue_name
                  website
                }
              }
            }
          }
        }

            `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.checkInventoryLevelByEventProduct = (data) => {
    const query = {
        operationName: 'checkInventoryLevelByEventProduct',
        query: `query checkInventoryLevelByEventProduct($eventProductId: Int) {
    event_products(where: {event_products_id: {_eq: $eventProductId}}) {
      quantity
    }
  }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getProductsByOrgProducts = (data) => {
    const query = {
        operationName: 'getEventProductsByOrgProducts',
        query: `query getEventProductsByOrgProducts($products: [Int!], $org_id: Int = 10) {
      event_products(where: {product_id: {_in: $products}, product: {org_id: {_eq: $org_id}}}) {
        absorb_fee_1
        absorb_fee_2
        absorb_fee_3
        absorb_fee_4
        absorb_fee_5
        absorb_fee_cc
        absorb_fee_platform
        boxoffice_price
        checkin_info
        channels
        count_oi
        created_at
        delivery_methods
        discount_value
        discount_type
        display_end_date
        display_image_product_card
        display_image_product_detail
        display_order
        display_public
        display_start_date
        display_to_team_sub_type
        display_to_team_type
        event_id
        event_products_id
        fee_1
        fee_2
        fee_3
        fee_4
        fee_5
        groups
        guest_tickets_price
        hide_package_description
        is_package
        is_variant
        max_per_order
        max_per_person
        max_per_team
        max_quantity
        min_per_order
        override_product_name
        override_description
        override_short_description
        package_display_name
        package_event_products
        photos
        price
        product_id
        quantity
        restrict_to_group
        sale_end_dttm
        sale_start_dttm
        seatsio
        sold_out_state
        status
        tags
        taxes
        transferable
        updated_at
        variant_display_name
        variants
        video_url
        visibility_option
        visibility_product_link
        product {
          default_location
          product_display_name
          product_group
          product_group_display_order
          product_type
          product_id
          product_type_display_order
          status
          price
          is_wristband
          is_media
          is_admission
          is_hardcard
          display_image_product_card
          display_image_product_detail
          product_description
          product_name
          short_product_description
          transferable
        }
        event {
          end_date
          event_display_name
          event_display_name_2
          display_channel
          event_notices
          event_id
          house_account_id
          image
          image_square
          internal_use
          name
          org_id
          signup_confirmation_image
          start_date
          status
          taxable
          timezone
          type
          url_name
          venue_id
          venue {
            address_1
            address_2
            city
            country
            display_location
            phone_number
            postal_code
            state_province
            tax_rate
            venue_id
            venue_name
            website
          }
        }
        order_items_aggregate(where: {order: {status: {_eq: "paid"}}}) {
          aggregate {
            count
          }
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getVehicleNumbersByOrgByGroup = (data) => {
    const query = {
        operationName: 'getVehicleNumbersByOrgByGroup',
        query: `query getVehicleNumbersByOrgByGroup($org_id: Int, $groups: jsonb, $statuses: [String!],) {
          vehicle_numbers(where: {org_id: {_eq: $org_id}, group: {_contained_in: $groups}, status: {_in: $statuses}}) {
            claimed_by
            claimed_user {
              email
              full_name
              first_name
              last_name
              headshot
              profile_photo
              user_id
            }
            assigned_by
            assigned_by_user {
              email
              first_name
              full_name
              last_name
              headshot
              profile_photo
            }
            vehicle_number
            vehicle_id
            updated_at
            status
            org_id
            id
            group
            event_id
            event {
              name
              end_date
              event_display_name
              image
              image_square
              start_date
            }
          }
        }

    `,
        variables: data
    }
    return makeRequest(query)
}
GraphqlQueries.getVehicleNumbersByOrg = (data) => {
    const query = {
      operationName: 'getVehicleNumbersByOrg',
      query: `query getVehicleNumbersByOrg($org_id: Int, $statuses: [String!]) {
        vehicle_numbers(where: {org_id: {_eq: $org_id}, status: {_in: $statuses}}) {
          claimed_by
          claimed_user {
            email
            full_name
            first_name
            last_name
            headshot
            profile_photo
            user_id
            order_items(where: {product: {is_driver_reg: {_eq: true}}, status: {_in: ["confirmed", "checked in"]}, org_id: {_eq: $org_id}}) {
              status
              event_product {
                event {
                  event_display_name
                  season {
                    season_id
                    season_display_name
                  }
                }
              }
            }
          }
          assigned_by
          assigned_by_user {
            email
            first_name
            full_name
            last_name
            headshot
            profile_photo
          }
          vehicle_number
          vehicle_id
          notes
          updated_at
          status
          org_id
          id
          group
          event_id
          event {
            name
            end_date
            event_display_name
            image
            image_square
            start_date
          }
          created_at
          id
        }
      }`,
      variables: data
    }
    return makeRequest(query)
}
GraphqlQueries.getVehicleNumberStatusByPK = (data) => {
    const query = {
        operationName: 'getVehicleNumberStatusByPK',
        query: `query getVehicleNumberStatusByPK($id: Int!) {
          vehicle_numbers_by_pk(id: $id) {
            status
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}
GraphqlQueries.getEventsByOrgByChannel = (data) => {
    const query = {
        operationName: 'getEventsByOrgByChannel',
        query: `query getEventsByOrgByChannel($org_id: Int, $display_channels: jsonb) {
          events(where: {org_id: {_eq: $org_id}, display_channel: {_contains: $display_channels}}, order_by: {start_date: asc}) {
            org_id
            event_display_name
            event_display_name_2
            start_date
            url_name
            display_channel
            end_date
            name
            image
            internal_use
            event_id
            status
            type
            venue {
              venue_name
              website
              venue_id
              state_province
              postal_code
              phone_number
              country
              city
              address_1
              address_2
            }
          }
        }

        `,
        variables: data
    }
    return makeRequest(query)
}
GraphqlQueries.getEventsByOrgSimple = (data) => {
    const query = {
        operationName: 'getEventsByOrgSimple',
        query: `query getEventsByOrgSimple($org_id: Int) {
          events(where: {org_id: {_eq: $org_id}}, order_by: {start_date: asc}) {
            org_id
            event_display_name
            event_display_name_2
            start_date
            url_name
            display_channel
            end_date
            name
            image
            info_file
            info_modal
            internal_use
            event_id
            schedule_file
            schedule_modal
            status
            type
            venue {
              venue_name
              website
              venue_id
              state_province
              postal_code
              phone_number
              country
              city
              address_1
              address_2
            }
          }
        }

        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOrgTasks = (tasks) => {
    const query = {
        operationName: 'getOrgTasks',
        query: `query getOrgTasks($org_id: Int = ${Vue.prototype.$org.org_id}, $is_tech: Boolean = false, $is_media: Boolean = false) {
  tasks(where: {org_id: {_eq: $org_id}, is_tech: {_eq: $is_tech}, is_media: {_eq: $is_media}}) {
    auto_approve
    created_dttm
    due_dttm
    form_id
    is_tech
    is_media
    media_application_payload
    name
    note
    org_id
    platform
    restrict_to_user
    status
    submission_id
    submission_payload
    submitted_by
    task_id
    team {
      team_name
      type
      sub_type
    }
    team_id
    team_profile_id
    type
    user_id
    vehicle_id
    vehicle_number_groups
    task_user_id {
      email
      first_name
      full_name
    }
    submitted_by_user {
      email
      first_name
      full_name
    }
    form_submission {
      created_at
      event_product_id
      form_id
      form_schema
      form_submission_uuid
      id
      order_item_id
      org_id
      status
      submission_data
      submission_task_id_int
      team_id
      type
      updated_at
      user_id
      form {
        form_uuid
      }
    }
  }
}`,
        variables: tasks
    }
    return makeRequest(query)
}

GraphqlQueries.getAcceptedPitSpace = (tasks) => {
    const query = {
        operationName: 'getAcceptedPitSpace',
        query: `query getAcceptedPitSpace($org_id: Int = ${Vue.prototype.$org.org_id}, $is_tech: Boolean = false, $is_media: Boolean = false) {
          tasks(where: {org_id: {_eq: $org_id}, is_tech: {_eq: $is_tech}, is_media: {_eq: $is_media}, name: {_eq: "Pit Space"}, status: {_eq: "accepted"}}) {
            auto_approve
            created_dttm
            due_dttm
            form_id
            is_tech
            is_media
            media_application_payload
            name
            note
            org_id
            platform
            restrict_to_user
            status
            submission_id
            submitted_by
            task_id
            team {
              team_name
            }
            team_id
            team_profile_id
            type
            user_id
            vehicle_id
            vehicle_number_groups
            task_user_id {
              email
              first_name
              full_name
            }
            submitted_by_user {
              email
              first_name
              full_name
            }
          }
}`,
        variables: tasks
    }
    return makeRequest(query)
}

GraphqlQueries.getEventsWithProductsByChannel = (tasks) => {
    const query = {
        operationName: 'getEventsWithProductsByChannel',
        query: `query getEventsWithProductsByChannel($org_id: Int, $channel: jsonb) {
          events(where: {org_id: {_eq: $org_id}, status: {_nin: ["draft", "past"]}, display_channel: {_contains: $channel}}) {
            end_date
            status
            event_display_name
            event_notices
            display_channel
            event_id
            default_group
            test_mode
            image_square
            more_info
            taxable
            default_product_image
            event_display_name_2
            house_account {
              email
              user_id
              full_name
              first_name
              last_name
              is_house_account
            }
            signup_confirmation_image
            name
            image
            organization {
              org_name
              org_logo
              org_website
              subdomain
              org_logo_alt
              application_fee_amount
              stripe_id
              org_legal_name
              cc_processing_amount
              application_fee_type
              org_fee {
                config
              }
            }
            start_date
            venue {
              address_1
              address_2
              city
              country
              postal_code
              state_province
              venue_name
              website
              tax_rate
              display_location
            }
            venue_id
            event_products(where: {status: {_in: ["active"]}, channels: {_contains: [$channel]}}) {
              quantity
              channels
              product_id
              disclaimer_text
              restrict_to_group
              groups
              display_to_team_type
              display_to_team_sub_type
              event_products_id
              price
              sold_out_state
              sold_out_image
              waitlist
              waitlist_url
              application
              application_url
              more_info
              event_id
              override_product_name
              override_short_description
              override_description
              event {
                default_product_image
                event_id
                event_display_name
                start_date
                end_date
                image_square
                venue {
                  address_1
                  address_2
                  city
                  country
                  postal_code
                  state_province
                  venue_name
                  website
                  tax_rate
                  display_location
                }
              }
              max_per_person
              max_per_team
              guest_tickets_price
              boxoffice_price
              display_order
              display_end_date
              display_start_date
              sale_start_dttm
              sale_end_dttm
              display_image_product_detail
              display_image_product_card
              video_url
              photos
              status
              taxes
              product {
                product_display_name
                product_group
                more_info
                disclaimer_text
                product_group_display_order
                product_name
                product_description
                short_product_description
                product_type
                product_type_display_order
                display_image_product_card
                requirements
                requirement {
                  fields
                  formsite_id
                  formsite_name
                  org_id
                  reg_group
                  req__display_name
                  req_description
                  req_html
                }
                product_requirements {
                  requirement {
                    req__display_name
                    req_description
                    req_html
                    req_name
                  }
                }
                order_fulfillment_items {
                  fulfilled_quantity
                  fulfillment_method
                  is_media
                  media_number
                  product_id
                  quantity
                  status
                }
              }
              event_products_id
              is_variant
              variants
              variant_display_name
              seatsio
              fee_5
              fee_4
              fee_3
              fee_2
              fee_1
              absorb_fee_1
              absorb_fee_2
              absorb_fee_3
              absorb_fee_4
              absorb_fee_5
              absorb_fee_cc
              absorb_fee_platform
              channels
              display_public
              hide_price
              visibility_option
              visibility_product_link
              is_package
              tags
              max_per_order
              min_per_order
              package_event_products
              display_to_team_sub_type
              display_to_team_type
            }
          }
        }
        `,
        variables: tasks
    }
    return makeRequest(query)
}
GraphqlQueries.getGroupsByUserIdAndOrg = (data) => {
    const query = {
        operationName: 'getGroupsByUserIdAndOrg',
        query: `query getGroupsByUserIdAndOrg($user_id: Int, $org_id: Int) {
          group_users(where: {user_id: {_eq: $user_id}, group: {org_id: {_eq: $org_id}}}) {
            user_id
            team_id
            partner_id
            media_outlet_id
            group_user_id
            group_id
            group {
              group_display_name
              group_id
              group_name
              org_id
            }
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamOrdersForDashboard = (data) => {
    const query = {
        operationName: 'getTeamOrdersForDashboard',
        query: `query getTeamOrdersForDashboard($org_id: Int, $team_id: Int) {
        orders(where: {org_id: {_eq: $org_id}, team_id: {_eq: $team_id}, status: {_eq: "paid"}}) {
          total
          pay_plan
          order_number
          order_create_dttm
          payment_type
          stripe_payment_id
          stripe_payload
          source_channel
          subscription_id: stripe_payload(path: "subscription")
          team_id
          metadata: stripe_payload(path: "metadata")
          order_items {
            event_product {
              event {
                name
              }
            }
            product {
              product_display_name
            }
          }
          user {
            full_name
            user_id
          }
        }
      }

          `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamUnassignedOrders = (data) => {
    const query = {
        operationName: 'getTeamUnassignedOrders',
        query: `query getTeamUnassignedOrders($team_id: Int, $org_id: Int) {
          order_items(where: {order: {org_id: {_eq: $org_id}, team_id: {_eq: $team_id}, status: {_eq: "paid"}}, assigned_user_id: {_is_null: true}, event_product: {event: {status: {_neq: "past"}}}}, order_by: {event_product: {event: {name: asc}}}) {
            order_items_id
            status
            order_number
            user_id
            team_id
            event_product_id
            assigned_user {
              user_id
              full_name
              email
            }
            product {
              product_display_name
              product_id
              product_type
              product_group
              product_description
              short_product_description
              is_hardcard
              is_wristband
              product_requirements {
                req_id
                product_requirements_id
                requirement {
                  req__display_name
                  req_description
                  req_html
                  req_id
                  req_name
                }
              }
            }
            event_product {
              event_products_id
              event_id
              event {
                name
                start_date
                end_date
                event_display_name
                event_display_name_2
              }
              display_image_product_card
              display_image_product_detail
              override_product_name
              override_description
              override_short_description
              product {
                product_display_name
                product_id
                product_type
                product_group
                product_description
                short_product_description
                is_hardcard
              }
            }
            user {
              team_users(where: {team_id: {_eq: $team_id}, role_id: {_in: [3, 53]}}) {
                role_id
              }
            }
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getTeamUserCredentials = (data) => {
    const query = {
        operationName: 'getTeamUserCredentials',
        query: `query getTeamUserCredentials($org_id: Int, $team_id: Int, $user_id: Int) {
          hard_cards(where: {org_id: {_eq: $org_id}, team_id: {_eq: $team_id}, assigned_user: {_eq: $user_id}, status: {_neq: "unpaid"}}) {
            hard_card_id
            order_item
            print_title
            hard_card_type
            order_items_id
            front_image
            event_id
            status
            hard_card_level {
              level_name
            }
            event {
              event_display_name_2
              event_display_name
            }
            order_item {
              product {
                product_display_name
              }
            }
            expiration
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getHardCardLevelByEventProducts = (data) => {
    const query = {
        operationName: 'getHardCardLevelByEventProducts',
        query: `query getHardCardLevelByEventProducts($event_products: jsonb, $org_id: Int) {
          hard_card_levels(where: {event_products: {_contains: $event_products}, org_id: {_eq: $org_id}}) {
            hard_card_level_id
            level_display_name
            level_name
            duration
            event_products
            event_product_id
            org_id
            is_temp
            status
            type
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}
GraphqlQueries.getTeamUserByTeamUserIdAndTeamId = (data) => {
    const query = {
        operationName: 'getTeamUserByTeamUserIdAndTeamId',
        query: `query getTeamUserByTeamUserIdAndTeamId($team_user_id: Int, $team_id: Int) {
          team_users(where: {team_user_id: {_eq: $team_user_id}, team_id: {_eq: $team_id}}) {
            role_id
            role {
              role_name
              role_id
            }
            main_contact
            primary_position
            primary_position_r {
              position_name
              positions_id
            }
            purchase_tickets
            team_positions {
              position {
                position_name
                positions_id
                req_id
                sub_channel
              }
            }
            request_credential
            status
            team_user_id
            user_id
            user {
              address_1
              address_2
              cell_phone
              city
              country
              email
              first_name
              full_name
              headshot
              last_name
              is_house_account
              postal_code
              profile_photo
              status
              state_province
              user_id
              user_uuid
            }
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getFormTemplateByPK = (data) => {
    const query = {
        operationName: 'getFormTemplateByPK',
        query: `query getFormTemplateByPK($form_template_id: Int = "") {
          form : form_templates_by_pk(form_template_id: $form_template_id) {
            form_schema,
            form_uuid
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getFormTemplateByUUID = (data) => {
    const query = {
        operationName: 'getFormTemplateByUUID',
        query: `query getFormTemplateByUUID($form_uuid: uuid!) {
          form : form_templates(where: {form_uuid: {_eq: $form_uuid}}) {
            form_uuid
            form_schema
            form_template_id
            is_media
            is_profile
            is_tech
            is_task
            name
            org_edit
            org_id
            status
            type
            updated_dttm
            user_edit
            auto_approve
            create_by
            create_dttm
            due_dttm
            is_single_submission
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getFormSubmissionsData = (data) => {
    const query = {
        operationName: 'getFormSubmissionsData',
        query: `query getFormSubmissionsData($form_id: [Int!], $org_id: Int = ${Vue.prototype.$org.org_id}) {
          form_submissions(where: {org_id: {_eq: $org_id}, form_id: {_in: $form_id}}) {
              created_at
              form_id
              form_schema
              id
              org_id
              submission_data
              status
              type
              updated_at
              user_id
            }
          }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOrgForms = (forms) => {
    const query = {
        operationName: 'getOrgForms',
        query: `query getOrgForms($org_id: Int = ${Vue.prototype.$org.org_id}, $status: String = "active") {
          form_templates(where: {org_id: {_eq: $org_id}, status: {_eq: $status}}) {
            approval_box
            auto_approve
            create_dttm
            create_by
            due_dttm
            form_schema
            form_template_id
            form_uuid
            is_media
            is_profile
            is_task
            is_tech
            name
            org_edit
            org_id
            status
            type
            updated_dttm
            user_edit
          }
        }`,
        variables: forms
    }
    return makeRequest(query)
}

GraphqlQueries.getTaskByID = (data) => {
    const query = {
        operationName: 'getTaskByID',
        query: `query getTaskByID($task_id: Int!) {
          task : tasks_by_pk(task_id: $task_id) {
            user_id
            type
            team_profile_id
            team_id
            task_id
            submitted_by
            submission_payload
            submission_id
            status
            restrict_to_user
            platform
            org_id
            note
            name
            media_application_payload
            is_tech
            is_media
            form_id
            due_dttm
            driver_profile_id
            created_dttm
            auto_approve
            approval_payload
            vehicle_id
            vehicle_number_groups
            submitted_by_user {
              email
              first_name
              full_name
              last_name
              user_id
            }
            task_user_id {
              email
              first_name
              full_name
              last_name
              user_id
            }
            team {
              team_name
              team_id
            }
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getFormSubmissionsToApprove = (data) => {
    const query = {
        operationName: 'getFormSubmissionsToApprove',
        query: `query getFormSubmissionsToApprove($form_id: Int!) {
          approvals : form_submissions(where: {form_id: {_eq: $form_id}}) {
            created_at
            event_product_id
            form_id
            form_submission_uuid
            id
            org_id
            submission_data
            status
            team_id
            type
            updated_at
            user_id
            user {
              cell_phone
              email
              first_name
              full_name
              last_name
            }
            team {
              team_name
              team_id
              type
              sub_type
            }
            event_product {
              event {
                event_display_name
              }
              product {
                product_display_name
              }
            }
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getFormSubmissionsToApproveNoTask = (data) => {
    const query = {
        operationName: 'getFormSubmissionsToApproveNoTask',
        query: `query getFormSubmissionsToApproveNoTask($form_id: Int!) {
          approvals : form_submissions(where: {form_id: {_eq: $form_id}}) {
            created_at
            event_product_id
            form_id
            form_submission_uuid
            id
            org_id
            status
            submission_data
            team_id
            type
            updated_at
            user_id
            user {
              cell_phone
              email
              first_name
              full_name
              last_name
            }
            team {
              team_name
              team_id
              type
              sub_type
            }
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getFormSubmissionByUUID = (data) => {
    const query = {
        operationName: 'getFormSubmissionByUUID',
        query: `query getFormSubmissionByUUID($form_submission_uuid: uuid) {
          approval : form_submissions(where: {form_submission_uuid: {_eq: $form_submission_uuid}}) {
            approval_notes
            created_at
            event_product_id
            order_item_id
            form_id
            form_submission_uuid
            id
            org_id
            submission_data
            team_id
            type
            updated_at
            user_id
            user {
              email
              first_name
              full_name
              last_name
            }
            team {
              team_name
              team_id
              type
              sub_type
            }
            form {
              approval_box
              auto_approve
              create_dttm
              create_by
              due_dttm
              form_template_id
              form_uuid
              is_media
              is_profile
              is_task
              is_tech
              name
              org_edit
              org_id
              status
              type
              updated_dttm
              user_edit
              form_schema
              allow_new_submission
            }
            form_schema
            status
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}
GraphqlQueries.getTeamOrderCountByEventProductId = (data) => {
    const query = {
        operationName: 'getTeamOrderCountByEventProductId',
        query: `query getTeamOrderCountByEventProductId($team_id: Int, $event_product_id: Int) {
          order_items: order_items_aggregate(where: {event_product_id: {_eq: $event_product_id}, team_id: {_eq: $team_id}, status: {_nin: ["canceled", "cancelled", "refunded"]}, order: {status: {_eq: "paid"}}}) {
            aggregate {
              count
            }
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getEventProductLimits = (data) => {
    const query = {
        operationName: 'getEventProductLimits',
        query: `query getEventProductLimits($event_products_id: Int!) {
          event_product: event_products_by_pk(event_products_id: $event_products_id) {
            max_per_order
            max_per_person
            max_per_team
            max_quantity
            min_per_order
            quantity
            status
            restrict_to_group
            display_to_team_type
            display_to_team_sub_type
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getTBDrivers = (data) => {
    const query = {
        operationName: 'getTBDrivers',
        query: `query getTBDrivers {
          torkbox_drivers {
            car_number
            headshot
            id
            name
            series
            team_id
            torkbox_device_id
            user_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getTBBracket = (data) => {
    const query = {
        operationName: 'getTBBracket',
        query: `query getTBBracket {
          basic_bracket {
            battle_number
            id
            torkbox_driver_id
            torkbox_driver {
              car_number
              headshot
              id
              name
              series
              team_id
              torkbox_device_id
              user_id
            }
            tag
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getMyTasks = (data) => {
    const query = {
        operationName: 'getMyTasks',
        query: `query getMyTasks($user_id: Int) {
          form_submissions(where: {user_id: {_eq: $user_id}}) {
              created_at
              event_product_id
              form {
                auto_approve
                due_dttm
                form_schema
                form_uuid
                is_headshot
                is_media
                is_profile
                is_task
                is_tech
                name
                org_edit
                org_id
                status
                type
                updated_dttm
                user_edit
                allow_new_submission
              }
              form_id
              form_schema
              form_submission_uuid
              id
              order_item_id
              org {
                org_name
              }
              org_id
              status
              submission_data
              submission_task_id_int
              task {
                status
              }
              team_id
              type
              updated_at
              user_id
            }
          }`,
        variables: data
    }
    return makeRequest(query)
}
GraphqlQueries.getMyTasksByUserID = (data) => {
    const query = {
        operationName: 'getMyTasksByUserID',
        query: `query getMyTasksByUserID($user_id: Int) {
          tasks(where: {user_id: {_eq: $user_id}, _or: [
              { platform: { _eq: "vehicle_number_selector" } },
              { platform: { _eq: "torkform" }, status: { _eq: "pending" } }
            ]
          }) {
            approval_payload
            auto_approve
            created_dttm
            driver_profile_id
            due_dttm
            form_id
            is_media
            is_tech
            media_application_payload
            name
            note
            org {
              org_name
            }
            org_id
            platform
            restrict_to_user
            status
            submission_id
            submission_payload
            submitted_by
            task_id
            team_id
            team_profile_id
            type
            user_id
            vehicle_id
            vehicle_number_groups
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getFDSwappableProducts = (data) => {
    const query = {
        operationName: 'getFDSwappableProducts',
        query: `query getFDSwappableProducts($event_id: [Int!] ) {
          event_products(where: {event_id: {_in: $event_id}, seatsio: {_eq: false}, channels: {_contains: "allocations"}, event: {org_id: {_eq: 1}}, product: {product_type: {_ilike: "spectator ticket"}, quantity: {_gt: 0}}, status: {_eq: "active"}}) {
            access_end
            access_start
            channels
            created_at
            delivery_methods
            display_image_product_card
            display_image_product_detail
            display_order
            event_id
            event_products_id
            groups
            is_package
            is_variant
            max_per_order
            max_per_team
            max_per_person
            max_quantity
            min_per_order
            override_description
            override_product_name
            override_short_description
            package_event_products
            photos
            product_id
            price
            package_display_name
            seatsio
            sold_out_state
            display_start_date
            status
            quantity
            tags
            updated_at
            variant_display_name
            variants
            visibility_option
            visibility_product_link
            product {
              accounting_code
              add_ons
              checkin_notes
              created_at
              delivery
              display_image_product_card
              display_image_product_detail
              event_id
              fill_input_type
              fulfillment_color
              fulfillment_image
              is_wristband
              is_hardcard
              product_description
              product_display_name
              product_group
              quantity
              req_id
              requirements
              scanner_db_id
              short_product_description
              status
              stripe_id
              stripe_price_id
              stripe_tax_id
              updated_at
              product_type
              product_type_display_order
            }
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getFDTeamWristbandProductByEventID = (data) => {
    const query = {
        operationName: 'getFDTeamWristbandProductByEventID',
        query: `query getFDTeamWristbandProductByEventID($event_id: [Int!] ) {
          event_products(where: {event_id: {_in: $event_id}, event: {org_id: {_eq: 1}}, product: {quantity: {_gt: 0}}, status: {_eq: "active"}, product_id: {_eq: 933}}) {
            access_end
            access_start
            channels
            created_at
            delivery_methods
            display_image_product_card
            display_image_product_detail
            display_order
            event_id
            event_products_id
            groups
            is_package
            is_variant
            max_per_order
            max_per_team
            max_per_person
            max_quantity
            min_per_order
            override_description
            override_product_name
            override_short_description
            package_event_products
            photos
            product_id
            price
            package_display_name
            seatsio
            sold_out_state
            display_start_date
            status
            quantity
            tags
            updated_at
            variant_display_name
            variants
            visibility_option
            visibility_product_link
            product {
              accounting_code
              add_ons
              checkin_notes
              created_at
              delivery
              display_image_product_card
              display_image_product_detail
              event_id
              fill_input_type
              fulfillment_color
              fulfillment_image
              is_wristband
              is_hardcard
              product_description
              product_display_name
              product_group
              quantity
              req_id
              requirements
              scanner_db_id
              short_product_description
              status
              stripe_id
              stripe_price_id
              stripe_tax_id
              updated_at
              product_type
              product_type_display_order
            }
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOrderItemsByProductIdsAndEventIds = (data) => {
    const query = {
        operationName: 'getOrderItemsByProductIdsAndEventIds',
        query: `query getOrderItemsByProductIdsAndEventIds($product_ids: [Int!], $event_ids: [Int!], $org_id: Int = ${Vue.prototype.$org.org_id}) {
      order_items(where: {order: {status: {_eq: "paid"}}, event_product: {product: {product_id: {_in: $product_ids}, org_id: {_eq: $org_id}}, event_id: {_in: $event_ids}}}) {
        assigned_user {
          email
          full_name
          cell_phone
        }
        user {
          email
          full_name
          cell_phone
          team_users {
            team {
              team_name
              team_users(where: {role: {role_id: {_eq: 2}}}) {
                user {
                  driver: full_name
                }
              }
            }
          }
        }
        status
        order_items_uuid
        notes
        check_in_note
        event_product {
          access_start
          access_end
          channels
          variant_display_name
        }
        order {
          company
          cre_dttm
        }
        order_number
        order_items_id
        product {
          product_display_name
          product_type
        }
        team {
          team_name
          team_users(where: {role: {role_id: {_eq: 2}}}) {
            user {
              driver: full_name
            }
          }
        }
        notes
        source_channel
        seatsio_order_item {
          sio_seat_id
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getWillCallMotorSportsOrders = (data) => {
    const query = {
        operationName: 'getWillCallMotorSportsOrders',
        query: `query getWillCallMotorSportsOrders ($org_id: Int = ${Vue.prototype.$org.org_id}) {
          order_items(where: {order: {status: {_eq: "paid"}}, event_product: {product: {is_driver_reg: {_eq: true}, org_id: {_eq: $org_id}}}}) {
            assigned_user {
              user_id
              email
              full_name
              cell_phone
            }
            user {
              user_id
              email
              full_name
              team_users {
                team {
                  team_name
                  team_users(where: {role: {role_id: {_eq: 2}}}) {
                    user {
                      driver: full_name
                    }
                  }
                }
              }
              cell_phone
            }
            status
            order_items_uuid
            check_in_note
            event_product {
              access_start
              access_end
              channels
              event {
                event_display_name
                event_display_name_2
                event_id
                name
              }
              variant_display_name
            }
            order {
              company
              team_id
              team {
                team_name
              }
              order_create_dttm
              source_channel
              userByAllocatedBy {
                first_name
                full_name
                email
                cell_phone
                last_name
              }
            }
            order_number
            order_items_id
            product {
              product_display_name
              product_type
            }
            team {
              team_name
              team_users(where: {role: {role_id: {_eq: 2}}}) {
                user {
                  driver: full_name
                }
              }
            }
            notes
            source_channel
            seatsio_order_item {
              sio_seat_id
            }
            hard_cards {
              duplex_print
              hard_card_id
            }
            media_status
            wristband_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getGridLifeOrbitsData = (data) => {
    const query = {
        operationName: 'getGridLifeOrbitsData',
        query: `query getGridLifeOrbitsData($event_id: Int) {
          gridlife_orbits_list_all_classes(where: {event_id: {_eq: $event_id}}) {
            SID
            DRVR_
            Name_First
            Name_Last
            Year
            Make
            Model
            Drivetrain
            TRNSP_
            TRNSP_Status
            CLASS_DRTN
            CLASS
            event_id
            group
            motorsports_data_id
            product_display_name
            team_name
            display_official_team_name
            official_team_name
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getGridLifeOrbitsDataTA = (data) => {
    const query = {
        operationName: 'getGridLifeOrbitsDataTA',
        query: `query getGridLifeOrbitsDataTA {
          gridlife_orbits_ta_list {
            SID
            DRVR_
            Name_First
            Name_Last
            Year
            Make
            Model
            CLASS
            Drivetrain
            TRNSP_
            TRNSP_Status
            CLASS_DRTN
            event_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getGridLifeHPDEData = (data) => {
    const query = {
        operationName: 'getGridLifeHPDEData',
        query: `query getGridLifeHPDEData($event_id: Int) {
          gridlife_hpde_data: gridlife_hpde_list_all_events(where: {event_id: {_eq: $event_id}}) {
            cell_phone
            email
            event_id
            first_name
            last_name
            mod_list
            order_items_id
            past_track_experience
            product_display_name
            tire_mfg
            tire_model
            vehicle_make
            vehicle_model
            vehicle_year
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getRentalTransponders = (data) => {
    const query = {
        operationName: 'getRentalTransponders',
        query: `query getRentalTransponders($org_id: Int = ${Vue.prototype.$org.org_id}) {
          transponders(where: {type: {_eq: "rental"}, org_id: {_eq: $org_id}, inventory_status: {_eq: "stocked"}}, order_by: {secondary_id: asc}) {
            brand
            image
            inventory_status
            last_rented_event
            model
            notes
            org_id
            rented_by
            secondary_id
            updated_at
            updated_by
            user_id
            working_status
            type
            transponder_id
            number
          }
        }

      `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOrderItemStatusChanges = (data) => {
    const query = {
        operationName: 'getOrderItemStatusChanges',
        query: `query getOrderItemStatusChanges($order_item_id: Int) {
          get_order_item_change_log(args: {oi: $order_item_id}, where: {new_data: {_has_key: "status"}}) {
            new_data
            old_data
            created_at
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getMotorsportCheckInByPK = (data) => {
    const query = {
        operationName: 'getMotorsportCheckInByPK',
        query: `query getMotorsportCheckInByPK($motorsports_data_id: Int!) {
        motorsports_data: order_items_motorsports_data_by_pk(motorsports_data_id: $motorsports_data_id) {
          user {
            user_id
            first_name
            last_name
            full_name
            email
            cell_phone
            birthday
            headshot
            goldpass_2023
            shirt_size
          }
          order_item {
            assigned_user_id
            status
            event_product {
              event_products_id
              product {
                product_id
                product_display_name
                product_type
                product_team_type {
                  vehicle_number_group
                }
              }
            }
          }
          order_items_id
          motorsports_status
          run_class
          run_sub_class
          display_official_team_name
          event_id
          transponder_type
          vehicle_num {
            id
            vehicle_number
            group
          }
          garage {
            garage_id
            year
            make
            model
            drivetrain
            official_name
          }
          team {
            team_id
            team_name
            official_team_name
            sub_type
          }
          transponder {
            transponder_id
            type
            number
            secondary_id
          }
        }
      }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.fetchUserTasksByOrg = (data) => {
    const query = {
        operationName: 'fetchUserTasksByOrg',
        query: `query fetchUserTasksByOrg($user_id: Int, $org_id: Int) {
        tasks(where: {user_id: {_eq: $user_id}, org_id: {_eq: $org_id}}) {
          form_id
          status
          name
        }
      }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getMotorsportDataByOrgAndEvent = (data) => {
    const query = {
        operationName: 'getMotorsportDataByOrgAndEvent',
        query: `query getMotorsportDataByOrgAndEvent($event_id: Int, $org_id: Int) {
            motorsports_data: order_items_motorsports_data(where: {event_id: {_eq: $event_id}, org_id: {_eq: $org_id}}) {
              user {
                user_id
                first_name
                last_name
                full_name
                email
                cell_phone
                birthday
                headshot
                goldpass_2023
                shirt_size
              }
              order_item {
                order_items_id
                status
                event_product {
                  event_products_id
                  product {
                    product_id
                    product_display_name
                    product_type
                    product_team_type {
                      vehicle_number_group
                    }
                  }
                }
              }
              motorsports_status
              motorsports_data_id
              display_official_team_name
              is_timing_export
              run_class
              run_sub_class
              vehicle_num {
                id
                vehicle_number
                group
              }
              garage {
                garage_id
                year
                make
                model
                drivetrain
              }
              team {
                team_id
                team_name
                official_team_name
              }
              transponder {
                transponder_id
                type
                number
                secondary_id
              }  }
            }
            `,

        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getGridLifeBroadcastData = (data) => {
    const query = {
        operationName: 'getGridLifeBroadcastData',
        query: `query getGridLifeBroadcastData($event_id: Int) {
          gridlife_broadcast_list(where: {event_id: {_eq: $event_id}}) {
            vehicle_number
            motorsports_status
            user_id
            Name_First
            Name_Last
            full_name
            Hometown
            official_team_name
            Year
            Make
            Model
            CLASS_DRTN
            group
            motorsports_data_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getGridLifeTransponders = (data) => {
    const query = {
        operationName: 'getGridLifeTransponders',
        query: `query getGridLifeTransponders($org_id: Int = ${Vue.prototype.$org.org_id}) {
            transponders(where: {type: {_eq: "rental"}, org_id: {_eq: $org_id}}, order_by: {secondary_id: asc}) {
            secondary_id
            number
            inventory_status
            working_status
            brand
            rented_by
            image
            last_rented_event
            model
            notes
            org_id
            updated_at
            updated_by
            user_id
            type
            transponder_id
            transponder_order_items_motorsports {
              motorsports_data_id
            }
            transponder_rented_by {
              cell_phone
              email
              full_name
            }
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getMyGarage = (data) => {
    const query = {
        operationName: 'getMyGarage',
        query: `query getMyGarage($user_id: Int) {
          garage(where: {user_id: {_eq: $user_id}}) {
            garage_id
            make
            model
            official_name
            primary_color
            year
            tire_manufacturer
            tire_compound
            status
            rear_tire_width
            rear_tire_sidewall
            rear_tire_diameter
            modification
            user_id
            weight
            whp
            front_tire_width
            front_tire_sidewall
            front_tire_diameter
            form_submission_id
            ecu_manufacturer
            ecu_model
            drivetrain
            team_id
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getVehicleNumberByPK = (data) => {
    const query = {
        operationName: 'getVehicleNumberByPK',
        query: `query getVehicleNumberByPK($id: Int!) {
          vehicle_numbers_by_pk(id: $id) {
            assigned_by
            claimed_by
            created_at
            event_id
            group
            id
            notes
            org_id
            status
            updated_at
            vehicle_id
            vehicle_number
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getVehicleLibrary = (data) => {
    const query = {
        operationName: 'getVehicleLibrary',
        query: `query getVehicleLibrary {
          vehicle_selector(where: {status: {_eq: "active"}}) {
            vehicle_selector_id
            status
            year
            make
            model
            image
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOwnedTranspondersByUser = (data) => {
    const query = {
        operationName: 'getOwnedTranspondersByUser',
        query: `query getOwnedTranspondersByUser($user_id: Int) {
          transponders(where: {user_id: {_eq: $user_id}, type: {_eq: "owned"}}) {
            brand
            notes
            transponder_id
            secondary_id
            org_id
            type
            user_id
            working_status
            inventory_status
            model
            number
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getUsersRegisteredVehicleNumbers = (data) => {
    const query = {
        operationName: 'getUsersRegisteredVehicleNumbers',
        query: `query getUsersRegisteredVehicleNumbers($user_id: Int, $org_id: Int) {
          vehicle_numbers(where: {claimed_by: {_eq: $user_id}, org_id: {_eq: $org_id}, status: {_eq: "registered"}}) {
            vehicle_number
            id
            group
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.lookForSubmissions = (data) => {
    const query = {
        operationName: 'lookForSubmissions',
        query: `query lookForSubmissions($user_id: Int, $org_id: Int = ${Vue.prototype.$org.org_id}, $form_id: Int ) {
          form_submission: form_submissions(where: {form_id: {_eq: $form_id}, user_id: {_eq: $user_id}, org_id: {_eq: $org_id}, status: {_in: ["approved", "submitted"]}}) {
              user_id
            }
          }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getTorkBoxDevices = (data) => {
    const query = {
        operationName: 'getTorkBoxDevices',
        query: `query getTorkBoxDevices{
          torkbox_devices (where: {active: {_eq: true}}, order_by: {device_id: asc}) {
            device_id
            device_name
            torkbox_devices_torkbox_drivers {
              id
              name
            }
            vehicle {
              ecu
            }
            team_id
            node_id
            user_id
            vehicle_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getTorkBoxDevicesByuser = (data) => {
    const query = {
        operationName: 'getTorkBoxDevicesByuser',
        query: `query getTorkBoxDevicesByuser($user_id: Int) {
          torkbox_devices(where: {user_id: {_eq: $user_id}, active: {_eq: true}}, order_by: {device_id: asc}) {
            device_id
            device_name
            torkbox_devices_torkbox_drivers {
              id
              name
            }
            vehicle {
              ecu
            }
            team_id
            node_id
            user_id
            vehicle_id
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getTorkBoxDevicesByTeam = (data) => {
    const query = {
        operationName: 'getTorkBoxDevicesByTeam',
        query: `query getTorkBoxDevicesByTeam($team_id: jsonb) {
          torkbox_devices(where: {team_id: {_contains: $team_id}, active: {_eq: true}}, order_by: {device_id: asc}) {
            device_id
            device_name
            torkbox_devices_torkbox_drivers {
              id
              name
            }
            vehicle {
              ecu
            }
            team_id
            node_id
            user_id
            vehicle_id
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOrgProducts = (data) => {
    const query = {
        operationName: 'getOrgProducts',
        query: `query getOrgProducts($org_id: Int = ${Vue.prototype.$org.org_id}) {
          products(where: {org_id: {_eq: $org_id}}) {
            org_id
            product_id
            product_name
            product_display_name
            product_type
            status
          }}`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getProductDetails = (data) => {
    const query = {
        operationName: 'getProductDetails',
        query: `query getProductDetails($product_id: Int) {
          products(where: {product_id: {_eq: $product_id}}) {
            accounting_code
            created_at
            display_image_product_card
            display_image_product_detail
            display_image_product_shopping_cart
            is_admission
            is_driver_reg
            is_hardcard
            is_media
            org_id
            product_display_name
            product_description
            product_group
            product_id
            product_name
            product_type
            price
            quantity
            short_product_description
            status
            tags
            transferable
            updated_at
            event_products {
              accounting_code
              boxoffice_price
              checkin_info
              created_at
              delivery_methods
              display_image_product_card
              display_image_product_detail
              display_order
              display_public
              channels
              event {
                end_date
                event_display_name
                event_display_name_2
                name
                status
                start_date
              }
              event_products_id
              event_id
              guest_tickets_price
              groups
              is_variant
              max_per_order
              max_per_person
              max_per_team
              max_quantity
              min_per_order
              more_info
              orders : order_items_aggregate {
                aggregate {
                  count
                }
              }
              override_description
              override_product_name
              override_short_description
              price
              quantity
              restrict_to_group
              sale_end_dttm
              sale_start_dttm
              status
              tags
              taxes
              transferable
              variant_display_name
              variants
              visibility_option
              updated_at
            }
            product_items {
              add_on_event_product_id
              add_on_product_id
              create_order_item
              name
              org_id
              product_id
              product_items_id
              quantity
              type
            }
          }
        }`,
          variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getDiscountCodeByNameOrgAndChannel = (data) => {
    const query = {
        operationName: 'getDiscountCodeByNameOrgAndChannel',
        query: `query getDiscountCodeByNameOrgAndChannel($code_name: String, $org_id: Int, $channel: jsonb) {
          discount_codes(where: {code_name: {_eq: $code_name}, active: {_eq: true}, org_id: {_eq: $org_id}, channels: {_contains: $channel}}) {
            active
            channels
            code_name
            discount_amount
            discount_code_id
            discount_type
            display_name
            org_id
            restrictions
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getPlatformDefaults = (data) => {
    const query = {
        operationName: 'getPlatformDefaults',
        query: `query getPlatformDefaults {
          platform_defaults(where: {}) {
            id
            table_name
            defaults
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOrgLists = (data) => {
    const query = {
        operationName: 'getOrgLists',
        query: `query getOrgLists($org_id: Int = ${Vue.prototype.$org.org_id}) {
          types: products(distinct_on: product_type, where: {org_id: {_eq: $org_id}}) {
            product_type
          }
           groups: products(distinct_on: product_group, where: {org_id: {_eq: $org_id}}) {
            product_group
          }
           product_tags: products(distinct_on: tags, where: {org_id: {_eq: $org_id}}) {
            tags
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getEventProductsByIdsOnly = (data) => {
    const query = {
        operationName: 'getEventProductsByIdsOnly',
        query: `query getEventProductsByIdsOnly($event_product_ids: [Int!]) {
          event_products(where: {event_products_id: {_in: $event_product_ids}}) {
            absorb_fee_1
            absorb_fee_2
            absorb_fee_3
            absorb_fee_4
            absorb_fee_5
            absorb_fee_cc
            absorb_fee_platform
            access_end
            access_start
            accounting_code
            boxoffice_price
            channels
            checkin_info
            created_at
            delivery_methods
            discount_type
            discount_value
            display_image_product_card
            display_image_product_detail
            display_order
            display_public
            display_to_team_sub_type
            display_to_team_type
            event {
              end_date
              event_id
              event_display_name
              start_date
            }
            event_products_id
            event_id
            fee_1
            fee_2
            fee_3
            fee_4
            fee_5
            groups
            guest_tickets_price
            hide_package_description
            is_package
            is_variant
            max_per_order
            max_per_team
            max_per_person
            max_quantity
            min_per_order
            package_event_products
            photos
            product_id
            price
            package_display_name
            seatsio
            sold_out_state
            display_start_date
            status
            quantity
            tags
            taxes
            updated_at
            variant_display_name
            variants
            video_url
            visibility_option
            visibility_product_link
            product {
              accounting_code
              add_ons
              checkin_notes
              created_at
              delivery
              display_image_product_card
              display_image_product_detail
              event_id
              fill_input_type
              fulfillment_color
              fulfillment_image
              is_wristband
              is_hardcard
              product_description
              product_display_name
              product_group
              quantity
              req_id
              requirements
              scanner_db_id
              short_product_description
              status
              stripe_id
              stripe_price_id
              stripe_tax_id
              updated_at
            }
            orders : order_items_aggregate {
              aggregate {
                count
              }
            }
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}
GraphqlQueries.getOrderItemDetialsByPK = (data) => {
    const query = {
        operationName: 'getOrderItemDetialsByPK',
        query: `query getOrderItemDetialsByPK($order_items_id: Int!) {
          order_item : order_items_by_pk(order_items_id: $order_items_id) {
            order_items_uuid
                    order_items_id
                    order_number
                    ticket_generated
                    attendee_can_transfer
                    assigned_user_id
                    assigned_user {
                      email
                      first_name
                      last_name
                      full_name
                    }
                    status
                    purchaser : user {
                      first_name
                      last_name
                      full_name
                      email
                    }
                    product {
                      product_name
                      product_type
                      product_display_name
                      default_location
                      delivery
                      product_id
                      transferable
                      fulfillment_color
                      fulfillment_image
                      product_requirements {
                        req_id
                        product_requirements_id
                        requirement {
                          req__display_name
                          req_description
                          req_html
                          req_id
                          req_name
                        }
                      }
                    }
                    event_product {
                      delivery_methods
                      variant_display_name
                      access_end
                      access_start
                      event_products_id
                      display_end_date
                      display_start_date
                      checkin_info
                      transferable
                      event {
                        event_id
                        start_date
                        end_date
                        name
                        image
                        timezone
                        venue {
                          venue_id
                          venue_name
                          city
                          state_province
                          postal_code
                        }
                        organization {
                          org_name
                        }
                        event_display_name
                        event_display_name_2
                      }
                    }
                    order_fulfillment_items {
                      product_id
                      product {
                        product_name
                        fulfillment_color
                        fulfillment_image
                      }
                      fulfilled_quantity
                      fulfillment_method
                      quantity
                      status
                    }
                    order_requirement_items {
                      status
                      due_date
                      requirement {
                        req_id
                        req_name
                        req_description
                        formsite_id
                        formsite_name
                        formsite_url
                        url_slugs
                        platform
                        form_uuid
                        expiration_dttm
                        expiration_type
                        form {
                          auto_approve
                        }
                      }
                      status
                      submission_data
                      submitted_by
                      submitted_dttm
                      assigned_user_id
                      order_requirement_items_id
                    }
                    seatsio_order_item {
                      sio_seat_id
                      status
                      seatsio_payload(path: "objects")
                    }
                    team {
                      team_name
                    }
                    ticket_transfers(order_by: {ticket_transfer_id: desc}, where: {transfer_status: {_neq: "cancelled"}}) {
                      order_item_id
                      ticket_transfer_id
                      transfer_status
                      transfer_to
                      transfer_from: user_id
                      receiver: transfer_user  {
                        email
                        full_name
                        cell_phone
                        first_name
                        user_id
                      }
                      sender:user {
                        email
                        cell_phone
                        full_name
                        first_name
                        user_id
                      }
                    }
          }
        }

        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOrderItemInformation = (data) => {
  const query = {
      operationName: 'getOrderItemInformation',
      query: `query getOrderItemInformation($order_number: Int!) {
        record: orders_by_pk(order_number: $order_number) {
          user_id
          order_number
          status
          price
          total
          tax
          cre_dttm
          user {
            full_name
            email
            cell_phone
          }
          org_id
          order_items {
            order_items_id
            status
            user {
              full_name
              email
              cell_phone
            }
            product {
              product_type
              product_display_name
            }
            event_product {
              event {
                name
              }
              event_products_id
              groups
              guest_tickets_price
              quantity
              seatsio
              status
              variant_display_name
              package_display_name
            }
            assigned_user {
              email
              full_name
              cell_phone
            }
            seatsio_order_item {
              sio_seat_id
              status
              seatsio_payload(path: "objects")
            }
            discount
            discount_reason
            item_cc_fee
            item_fee_1
            item_fee_2
            item_fee_3
            item_fee_4
            item_fee_5
            item_platform_fee
            modified_by
            notes
            org_id
            quantity
            price
            source_channel
          }
          order_cc_fee
          order_create_dttm
          order_fee_1
          order_fee_2
          order_fee_3
          order_fee_4
          order_fee_5
          order_platform_fee
          payment_type
          stripe_charge_id
          stripe_last4
          stripe_payload
          stripe_payment_id
          stripe_payout_date
          stripe_payout_fee
          stripe_payout_gross
          stripe_payout_id
          stripe_payout_net
          stripe_receipt_url
          stripe_txt_id
          fees
          discount
          boxoffice_user
          company
          pay_plan
          source_channel
          team_id
          team {
            team_name
          }
        }
      }`,
      variables: data
  }
  return makeRequest(query)
}

GraphqlQueries.getOrdersByOrg = (data) => {
  let whereClause = null
  const sortField = data.sortField || 'order_number'
  const sortOrder = data.sortOrder || 'desc'

  switch (data.searchType) {
    case 'order_number':
      whereClause = `{org_id: {_eq: ${data.org_id}}, status: {_neq: "unpaid"}, order_number: {_eq: ${data.searchValue}}}`
      break
    case 'user':
      whereClause = `{org_id: {_eq: ${data.org_id}}, status: {_neq: "unpaid"}, user: {full_name: {_ilike: "%${data.searchValue}%"}}}`
      break
    case 'source_channel':
      whereClause = `{org_id: {_eq: ${data.org_id}}, status: {_neq: "unpaid"}, source_channel: {_eq: "${data.searchValue}"}}`
      break
    case 'payment_type':
      whereClause = `{org_id: {_eq: ${data.org_id}}, status: {_neq: "unpaid"}, payment_type: {_eq: "${data.searchValue}"}}`
      break
    case 'status':
      whereClause = `{org_id: {_eq: ${data.org_id}}, status: {_eq: ${data.searchValue}}}`
      break
    default:
      whereClause = `{org_id: {_eq: ${data.org_id}}, status: {_neq: "unpaid"}}`
  }
  // check if whereclause is null or not a string
  if (!whereClause || typeof whereClause !== 'string') return Promise.reject(new Error('No where clause provided'))

  const query = {
    operationName: 'getOrdersByOrg',
    query: `query getOrdersByOrg {
      orders(where: ${whereClause}, order_by: {${sortField}: ${sortOrder}}, limit: ${data.rows}, offset: ${data.first}) {
        accounting_event_id
        user {
          full_name
          email
          cell_phone
          headshot
          address_1
          address_2
          city
          state_province
          postal_code
          country
        }
        allocated_by
        boxoffice_user
        company
        discount
        fees
        invoice_id
        order_cc_fee
        order_create_dttm
        order_date_to_event_date
        order_fee_1
        order_fee_2
        order_fee_3
        order_fee_4
        order_fee_5
        order_number
        order_platform_fee
        order_uuid
        outlet_id
        partner_id
        pay_plan
        payment_type
        price
        promo_code
        source_channel
        status
        stripe_charge_id
        stripe_last4
        stripe_payload
        stripe_payment_id
        stripe_payout_date
        stripe_payout_fee
        stripe_payout_gross
        stripe_payout_id
        stripe_payout_net
        stripe_receipt_url
        stripe_txt_id
        tax
        team_id
        th_service_notes
        total
        updated_dttm
        order_items: order_items_aggregate {
          aggregate {
            count
          }
          nodes {
            event_product {
              event {
                name
              }
            }
          }
        }
      }
      total_orders: orders_aggregate(where: ${whereClause}) {
        aggregate {
          count
        }
      }
    }`
  }

  return makeRequest(query)
}

GraphqlQueries.getOrderSourcesByOrg = (data) => {
  const query = {
      operationName: 'getOrderSourcesByOrg',
      query: `query getOrderSourcesByOrg( $org_id: Int) {
        results: order_sources_by_org(where: {org_id: {_eq: $org_id}}) {
          org_id
          source_channel
        }
      }`,
      variables: data
  }
  return makeRequest(query)
}
GraphqlQueries.getOrgVenues = (data) => {
    const query = {
        operationName: 'getOrgVenues',
        query: `query getOrgVenues($org_id: Int = ${Vue.prototype.$org.org_id}) {
            venue(where: {_or: [{org_id_specific: {_eq: $org_id}}, {org_id_specific: {_is_null: true}}]}, order_by: {venue_name: asc}) {
              venue_name
              venue_id
              city
              country
              address_1
              address_2
              display_location
              postal_code
              state_province
              website
              org_id_specific
              phone_number
            }
          }`,
        variables: data
    }
    return makeRequest(query)
}
GraphqlQueries.getChangeLog = (data) => {
    const query = {
        operationName: 'getChangeLog',
        query: `query getChangeLog($id: Int, $table_name: String) {
          results : get_change_logs_by_table_and_pk(args: {id: $id, table_name: $table_name}) {
            old_data
            new_data
            created_at
            change_log_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getUsersTeams = (data) => {
  const query = {
      operationName: 'getUsersTeams',
      query: `query getUsersTeams($org_id: Int = ${Vue.prototype.$org.org_id}, $user_id: Int) {
        teams(where: {org_id: {_eq: $org_id}, team_users: {user_id: {_eq: $user_id}, main_contact: {_eq: true}}, status: {_eq: "active"}}) {
          official_team_name
          status
          sub_type
          team_id
          team_name
          type
        }
      }`,
      variables: data
  }
  return makeRequest(query)
}

GraphqlQueries.getCrewList = (data) => {
  const query = {
      operationName: 'getCrewList',
      query: `query getCrewList($org_id: Int = ${Vue.prototype.$org.org_id}) {
        team_users(where: {team: {org_id: {_eq: $org_id}}, role_id: {_neq: 53}, status: {_nlike: "delete%"}}) {
          status
          team_user_id
          team_id
          user {
            user_id
            first_name
            last_name
            full_name
            email
            cell_phone
          }
          team {
            team_id
            team_name
            type
            sub_type
          }
          primary_position_r {
            position_name
            team_positions(distinct_on: position_id) {
              position {
                position_name
              }
            }
          }
          role {
            role_id
            role_name
          }
          team_positions (distinct_on: position_id) {
            position {
              position_name
              positions_id
            }
          }
        }
      }`,
      variables: data
  }
  return makeRequest(query)
}

GraphqlQueries.getPreviousSeatsIOOrders = (data) => {
  const query = {
      operationName: 'getPreviousSeatsIOOrders',
      query: `query getPreviousSeatsIOOrders($user_id: Int, $previous_event_id: Int) {
        order_items(where: {user_id: {_eq: $user_id}, seatsio_order_item: {status: {_eq: "booked"}}, event_product: {event_id: {_eq: $previous_event_id}}}) {
          assigned_user {
            email
            first_name
            full_name
            last_name
            status
          }
          seatsio_order_item {
            holdToken
            order_items_id
            purchase_date
            seatsio_payload
            sio_oi_id
            sio_seat_id
            status
            updated_dttm
          }
          status
          source_channel
          event_product {
            seatsio
            event_id
          }
          event_product_id
          order_items_id
        }
      }`,
      variables: data
  }
  return makeRequest(query)
}

GraphqlQueries.getCredits = (data) => {
  const query = {
      operationName: 'getCredits',
      query: `query getCredits($user_id: Int, $org_id: Int = ${Vue.prototype.$org.org_id}) {
        credits_with_current_balance(where: {user_id: {_eq: $user_id}, _or: [{expiration_dttm: {_gte: "now()"}}, {expiration_dttm: {_is_null: true}}], status: {_ilike: "active"}, org_id: {_eq: $org_id}}) {
          code
          created_dttm
          credit_id
          currency
          current_balance
          expiration_dttm
          notes
          org_id
          restrictions
          source_channel
          starting_balance
          status
          type
          user_id
          uuid
        }
      }`,
      variables: data
  }
  return makeRequest(query)
}

GraphqlQueries.getLatestUserOrder = (userId) => {
    const query = {
        operationName: 'getLatestUserOrder',
        query: `query getLatestUserOrder($user_id: Int) {
          orders(where: {user_id: {_eq: $user_id}, status: {_eq: "paid"}}, order_by: {cre_dttm: desc}, limit: 1) {
            cre_dttm
            status
            stripe_payment_id
            total
            user_id
            org_id
            order_number
            invoice_id
            discount
            fees
            order_cc_fee
            order_fee_1
            order_fee_2
            order_fee_3
            order_fee_4
            order_fee_5
            order_platform_fee
            pay_plan
            payment_type
            price
            promo_code
            source_channel
            stripe_charge_id
            stripe_payout_fee
            tax
          }
        }`,
        variables: userId
    }
    return makeRequest(query)
}

GraphqlQueries.getEmergencyContacts = (data) => {
    const query = {
        operationName: 'getEmergencyContacts',
        query: `query getEmergencyContacts($org_id: Int = ${Vue.prototype.$org.org_id}) {
          user_emergency_info(where: {user: {order_items: {org_id: {_eq: $org_id}}}, _or: {user: {assigned_order_items: {org_id: {_eq: $org_id}}}}}) {
            user {
              user_id
              first_name
              last_name
            }
            emergency_contact_name
            emergency_contact_mobile
            emergency_contact_relationship
            medical_conditions
            blood_type
            allergies
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getMotorsportsFormSubmissions = (data) => {
    const query = {
        operationName: 'getMotorsportsFormSubmissions',
        query: `query getMotorsportsFormSubmissions {
          form_submissions(where: {form: {is_motorsports_data: {_eq: true}, season_id: {_eq: 1}}}) {
            id
            form_submission_uuid
            type
            status
            created_at
            updated_at
            form {
              form_template_id
              name
            }
            order_item {
              product {
                product_display_name
              }
              event_product {
                event {
                  event_display_name
                }
              }
            }
            team {
              team_id
              team_name
              type
              sub_type
            }
            user {
              first_name
              last_name
              email
              cell_phone
            }
            user_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOrgDrivers = (data) => {
    const query = {
        operationName: 'getOrgDrivers',
        query: `query getOrgDrivers($org_id: Int = ${Vue.prototype.$org.org_id}) {
          order_items(where: {product: {is_driver_reg: {_eq: true}, org_id: {_eq: $org_id}}, order: {status: {_eq: "paid"}}}, distinct_on: user_id) {
            user {
              full_name
              first_name
              email
              last_name
              user_id
            }
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getGroupUsers = (data) => {
  const query = {
      operationName: 'getGroupUsers',
      query: `query getGroupUsers ($group_id: Int, $org_id: Int = ${Vue.prototype.$org.org_id}){
        group_users(where: {group_id: {_eq: $group_id}, group: {org_id: {_eq: $org_id}}}) {
          group_user_id
          user {
            user_id
            first_name
            last_name
            full_name
            email
            cell_phone
            headshot
            address_1
            address_2
            city
            state_province
            postal_code
          }
          group {
            group_id
            group_name
            group_display_name
            type
          }
        }
      }`,
      variables: data
  }
  return makeRequest(query)
}

GraphqlQueries.getGroups = (data) => {
  const query = {
      operationName: 'getGroups',
      query: `query getGroups($org_id: Int = ${Vue.prototype.$org.org_id}) {
        groups(where: {org_id: {_eq: $org_id}}) {
          group_id
          group_name
          group_display_name
          type
          event_products_id
        }
      }`,
      variables: data
  }
  return makeRequest(query)
}

GraphqlQueries.getDriverRegProducts = (data) => {
  const query = {
      operationName: 'getDriverRegProducts',
      query: `query getDriverRegProducts($org_id: Int) {
        event_products(where: {product: {is_driver_reg: {_eq: true}, org_id: {_eq: ${Vue.prototype.$org.org_id}}}}, order_by: {event_products_id: asc}) {
          event_products_id
          status
          transferable
          price
          boxoffice_price
          channels
          restrict_to_group
          groups
          display_to_team_type
          display_to_team_sub_type
          max_quantity
          quantity
          max_per_team
          max_per_order
          max_per_person
          override_product_name
          variant_display_name
          application
          application_url
          waitlist
          waitlist_url
          restrict_to_group
          groups
          display_to_team_type
          display_to_team_sub_type
          event {
            event_id
            event_display_name
            season {
              season_display_name
            }
          }
          product {
            product_id
            product_type
            product_display_name
            transferable
            is_motorsports_data
            is_admission
            teamtype {
              team_type_id
              type
              sub_type
            }
          }
        }
      }`,
      variables: data
  }
  return makeRequest(query)
}

GraphqlQueries.getVariantDataByIdsOnly = (data) => {
    const query = {
        operationName: 'getVariantDataByIdsOnly',
        query: `query getVariantDataByIdsOnly($event_product_ids: [Int!]) {
          event_products(where: {event_products_id: {_in: $event_product_ids}, status: {_eq: "active"}}) {
            allow_purchase_groups
            quantity
            channels
            product_id
            disclaimer_text
            restrict_to_group
            groups
            display_to_team_type
            display_to_team_sub_type
            event_products_id
            price
            sold_out_state
            sold_out_image
            waitlist
            waitlist_url
            application
            application_url
            more_info
            event_id
            override_product_name
            override_short_description
            override_description
            event {
              event_id
              event_display_name
              start_date
              end_date
              image_square
              venue {
            address_1
            address_2
            city
            country
            postal_code
            state_province
            venue_name
            website
            tax_rate
            display_location
          }
          }
            max_per_person
            guest_tickets_price
            boxoffice_price
            display_order
            display_end_date
            display_start_date
            sale_start_dttm
            sale_end_dttm
            display_image_product_detail
            display_image_product_card
            video_url
            photos
            status
            taxes
            product {
              product_display_name
              product_group
              more_info
              disclaimer_text
              product_group_display_order
              product_name
              product_description
              short_product_description
              product_type
              product_type_display_order
              display_image_product_card
              requirements
              requirement {
                fields
                formsite_id
                formsite_name
                org_id
                reg_group
                req__display_name
                req_description
                req_html
              }
              product_requirements {
                requirement {
                  req__display_name
                  req_description
                  req_html
                  req_name
                }
              }
              order_fulfillment_items {
                fulfilled_quantity
                fulfillment_method
                is_media
                media_number
                product_id
                quantity
                status
              }
            }
            event_products_id
            is_variant
            variants
            variant_display_name
            seatsio
            fee_5
            fee_4
            fee_3
            fee_2
            fee_1
            absorb_fee_1
            absorb_fee_2
            absorb_fee_3
            absorb_fee_4
            absorb_fee_5
            absorb_fee_cc
            absorb_fee_platform
            channels
            display_public
            hide_price
            visibility_option
            visibility_product_link
            is_package
            tags
            max_per_order
            max_per_team
            min_per_order
            package_event_products
            display_to_team_sub_type
            display_to_team_type
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getMotorsportDataByUserID = (data) => {
    const query = {
        operationName: 'getMotorsportDataByUserID',
        query: `query getMotorsportDataByUserID($user_id: Int, $event_id: Int, $org_id: Int = ${Vue.prototype.$org.org_id}) {
            motorsports_data: order_items_motorsports_data(where: {user_id: {_eq: $user_id}, org_id: {_eq: $org_id}, event_id: {_neq: $event_id}, order_item: {product: {product_type: {_eq: "driver registration"}}}, motorsports_status: {_in: ["checkedin", "checked in"]}}, order_by: {motorsports_data_id: desc}) {
              user {
                user_id
                first_name
                last_name
                full_name
                email
                cell_phone
                birthday
                headshot
                goldpass_2023
                shirt_size
              }
              order_item {
                order_items_id
                status
                event_product {
                  event_products_id
                  product {
                    product_id
                    product_display_name
                    product_type
                    product_team_type {
                      vehicle_number_group
                    }
                  }
                }
              }
              motorsports_status
              motorsports_data_id
              display_official_team_name
              is_timing_export
              run_class
              run_sub_class
              vehicle_num {
                id
                vehicle_number
                group
              }
              garage {
                garage_id
                year
                make
                model
                drivetrain
              }
              team {
                team_id
                team_name
                official_team_name
              }
              transponder {
                transponder_id
                type
                number
                secondary_id
              }  }
            }
            `,

        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getRequirementsByUserID = (data) => {
    const query = {
        operationName: 'getRequirementsByUserID',
        query: `query getRequirementsByUserID($user_id: Int) {
          order_requirement_items(where: {assigned_user_id: {_eq: $user_id}}) {
            notes
            reject_notes
            status
            submission_data
            submitted_by
            submitted_dttm
            task_id
            updated_dttm
            requirement {
              platform
              req__display_name
              req_name
              url_slugs
              auto_approve_unless_expired
              expiration_dttm
              expiration_type
              fields
              form_uuid
              formsite_id
              formsite_name
              formsite_url
              lock_to_assigned_id
              org:organization {
                org_name
              }
              org_id
              reg_group
              req_description
              req_html
              req_id
              form {
                form_template_id
              }
            }
            order_requirement_items_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getGridLifeGLTCData = (data) => {
    const query = {
        operationName: 'getGridLifeGLTCData',
        query: `query getGridLifeGLTCData($event_id: Int) {
          gridlife_gltc_data: gridlife_gltc_list_all_events(where: {event_id: {_eq: $event_id}}) {
            cell_phone
            email
            event_id
            first_name
            last_name
            mod_list
            order_items_id
            past_track_experience
            product_display_name
            tire_mfg
            tire_model
            vehicle_make
            vehicle_model
            vehicle_year
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getFormSubmissionByID = (data) => {
    const query = {
        operationName: 'getFormSubmissionByID',
        query: `query getFormSubmissionByID($form_submission_id: Int!) {
          form_submissions_by_pk(id: $form_submission_id) {
            created_at
            event_product_id
            order_item_id
            form_id
            form_submission_uuid
            id
            org_id
            submission_data
            team_id
            type
            updated_at
            user_id
            user {
              email
              first_name
              full_name
              last_name
            }
            team {
              team_name
              team_id
              type
              sub_type
            }
            form {
              approval_box
              auto_approve
              create_dttm
              create_by
              due_dttm
              form_template_id
              form_uuid
              is_media
              is_profile
              is_task
              is_tech
              name
              org_edit
              org_id
              status
              type
              updated_dttm
              user_edit
              form_schema
              allow_new_submission
            }
            form_schema
            status
          }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getPreviousOrdersByProductID = (data) => {
    const query = {
        operationName: 'getPreviousOrdersByProductID',
        query: `query getPreviousOrdersByProductID($current_order_items_id: Int, $product_id: Int, $user_id: Int) {
          order_items(where: {order_items_id: {_neq: $current_order_items_id}, product_id: {_eq: $product_id}, user_id: {_eq: $user_id}, status: {_in: ["checked in", "checkedin"]}}, order_by: {order_items_id: asc}) {
            allocation_id
            assigned_dttm
            assigned_user_id
            attendee_can_transfer
            created_dttm
            discount
            discount_reason
            event_product_id
            item_cc_fee
            item_fee_1
            item_fee_2
            item_fee_3
            item_fee_4
            item_fee_5
            item_platform_fee
            media_oulet_id
            notes
            order_items_id
            order_items_qr_uri
            order_items_uuid
            order_number
            partner_id
            pay_plan
            price
            product_id
            purchaser_can_transfer
            quantity
            source_channel
            status
            submitted_dttm
            team_id
            updated_dttm
            user_id
            wristband_id
            order {
              cre_dttm
              order_create_dttm
              team_id
              team {
                team_name
              }
            }
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getRushSREvents = (data) => {
  const query = {
      operationName: 'getRushSREvents',
      query: `query getRushSREvents($org_id: Int = ${Vue.prototype.$org.org_id}) {
        event_products(where: {product: {org_id: {_eq: $org_id}, product_display_name: {_eq: "RUSH SR"}}}) {
          event_id
        }
      }`,
      variables: data
  }
  return makeRequest(query)
}

GraphqlQueries.getGridLifeTAData = (data) => {
    const query = {
        operationName: 'getGridLifeTAData',
        query: `query getGridLifeTAData($event_id: Int) {
          gridlife_ta_data: gridlife_ta_list_all_events(where: {event_id: {_eq: $event_id}}) {
            cell_phone
            email
            event_id
            first_name
            last_name
            mod_list
            order_items_id
            past_track_experience
            product_display_name
            tire_mfg
            tire_model
            vehicle_make
            vehicle_model
            vehicle_year
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getGridLifeRushData = (data) => {
    const query = {
        operationName: 'getGridLifeRushData',
        query: `query getGridLifeRushData($event_id: Int) {
          gridlife_rush_data: gridlife_rush_list_all_events(where: {event_id: {_eq: $event_id}}) {
            cell_phone
            email
            event_id
            first_name
            last_name
            mod_list
            order_items_id
            past_track_experience
            product_display_name
            tire_mfg
            tire_model
            vehicle_make
            vehicle_model
            vehicle_year
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlQueries.getOrderItemScanData = (data) => {
    const query = {
        operationName: 'getOrderItemScanData',
        query: `query getOrderItemScanData($order_item_uuid: String) {
          scan_data(where: {scan_data: {_eq: $order_item_uuid}, status: {_eq: "1"}}) {
            capture_type
            device_time
            scan_time
            scan_data
            scan_user
            tz
            scan_location
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

export default GraphqlQueries